import FormControl from '@mui/material/FormControl';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { Radio, RadioGroup } from "@mui/material";
import React, { useState, useEffect } from 'react';

export default function InputRut(props) {
  const [error, setError] = useState(false);

  useEffect(() => {
    props.errorAddress(error && props.option);
    
      setError(props.option === false && props.direccion.length < 10 ? true : false);
  }, [error, props.option]);

    const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    props.handleAddress(newAddress);
    
    if (props.option === false) {
      setError(newAddress.length < 10);
    }
  };

  const handleOptionChange = (value) => {
    props.handleOption(props.option === value ? null : value);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Box
        sx={{
          m: props.margin || 1,
          width: "100%",
          background: props.option === false ? "#BBDEFB" : "#E4E4E4",
          borderRadius: "0.5rem",
          padding: "2%",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid  size={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="body1" sx={{ paddingRight: 2 }}>
                ¿Esta dirección es la correcta del establecimiento?
              </Typography>
              <RadioGroup row value={props.option ?? null}>
                <FormControlLabel
                  value={true}
                  control={<Radio onClick={() => handleOptionChange(true)} />}
                  label="Sí"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio onClick={() => handleOptionChange(false)} />}
                  label="No"
                />
              </RadioGroup>
            </Box>
          </Grid>

          {
            props.option === false ? (
           <Grid container spacing={2} alignItems="center">
          <Grid size={12}><Divider ></Divider></Grid>
           <Grid size={12}>
                    <Typography
                        color="inherit"
                    >
                        Si la dirección no es la correcta por favor dejar la corrección en el siguiente campo,  MINEDUC comprobará su cambio y actualizará el registro.
                    </Typography>
                </Grid>
          <Grid size={12} sx={{paddingBottom: 1}}>
            <TextField
              required
              id="outlined-required"
              label="Dirección actual del establecimiento"
              size="small"
              helperText={error ? "La dirección debe tener al menos 10 caracteres" : ""}
                  error={error}
                  value={props.direccion}
                  disabled={props.option !== false}
                  sx={{ width: "100%" }}
                  onChange={handleAddressChange}
            />
          </Grid>
        </Grid>) : null

          }

        </Grid>
      </Box>
    </FormControl>
  );
}

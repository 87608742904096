import React, { useState, useContext, useEffect, useRef } from "react";
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Divider from "@mui/material/Divider";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import ModalStandard from "../GeneralComponents/ModalStandard";
import InputFake from "../GeneralComponents/InputFake";
import { useNavigate } from "react-router-dom";
import InputRut from "../GeneralComponents/InputRut";
import InputFile from "../GeneralComponents/InputFile";
import Checkbox from "@mui/material/Checkbox";

import ContingenciasHiddenPreview from "./ContingenciasHiddenPreview";
import HourglassLoader from "../GeneralComponents/HourglassLoader";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { DAYS } from "../../Constants/Constants";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);
export default function Contingencias() {
  const { userSesion, setPageTitle, setDrawerHidding } =
    useContext(GeneralContext);
  const [RBDList, setRBDList] = useState([""]);
  const [rbdLoading, setRbdLoading] = useState(false);

  const [submitConfirm, setSubmitConfirm] = useState(false);
  //const [responseData, setResponseData] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [eesData, setEesData] = useState(null);
  const [autoCompleteInput, setAutoCompleteInput] = useState("");
  const autoCompleteRef = useRef(null);
  const datetimeRef = useRef(null);
  const datePRef = useRef(null);
  const timePRef = useRef(null);
  const daysPRef = useRef(null);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const rutRef = useRef(null);
  const evidenceRef = useRef(null);
  const zonasRef = useRef(null);
  const [evError, setEvError] = useState(false);
  const [errorRut, setErrorRut] = useState(false);
  const [evidence, setEvidence] = useState();
  const [autoCompleteInputZonas, setAutoCompleteInputZonas] = useState("");

  const contingenciasRef = useRef();
  const [formData, setFormData] = useState({
    name_emisor: userSesion.nombre,
    rut_emisor: userSesion.rut,
    nombre_colegio: "",
    id_operador: "",
    id_zona: "",
    operator_name: "",
    rbd: "",
    ees_managers: ["", ""],
    ees_managers_email: ["", ""],
    ees_temporal_manager_list: [""],
    ees_temporal_manager: "",
    ees_temporal_manager_email: "",
    checked: false,
    contingencia_programada: false,
    programada_descripcion: "",
    programada_inicio_contingencia: dayjs(),
    programada_fin_contingencia: dayjs(),
    programada_hora_inicio: dayjs().hour(0).minute(0),
    programada_hora_termino: dayjs().hour(23).minute(59),
    especifica_fecha_hora_resolucion: dayjs(),
    especifica_fecha_hora_ocurrencia: dayjs(),
    especifica_codigo_evento: "",
    especifica_origen_falla: "",
    especifica_extension_falla: "",
    especifica_descripcion_falla: "",
    days: DAYS.reduce((acc, dia) => ({ ...acc, [dia]: false }), {}),
    extension: "rbd",
    zonas: [],
  });
  const [zonas, setZonas] = useState([]);
  const [zonaData, setZonaData] = useState({});

  const handleChange = (event, newValue) => {
    
    if (newValue === "zona"){
      setFormData({ ...formData, extension: newValue, contingencia_programada: false, especifica_extension_falla: "Evento mayor" });
    }else{
      setFormData({ ...formData, extension: newValue });
    }
  };

  function getAllZones(id) {
    const fetchDataZone = async (id) => {
      try {
        const response = await axios.getZonasOperador(id);
        const newZonas = response.data.zonas.map((zona) => ({
          ...zona,
          nombre_zona: `ZONA${String(zona.id_zona).padStart(2, "0")}`,
        }));
        setZonas(newZonas);
      } catch (error) {
        console.error("Error en fetchDataZone:", error);
      }
    };
    fetchDataZone(id);
  }
  const handleDeleteRow = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      zonas: prevState.zonas.filter((_, i) => i !== index),
    }));
  };

  const handleAddRowZonas = () => {
    if (!autoCompleteInputZonas) return;

    const newZona = zonas.find(
      (zona) => zona.nombre_zona === autoCompleteInputZonas
    );

    if (!newZona) return;

    setFormData((prevState) => {
      const zonaExists = prevState.zonas.some(
        (zona) => zona.id_zona === newZona.id_zona
      );

      if (zonaExists) return prevState;

      return {
        ...prevState,
        zonas: [...prevState.zonas, newZona],
      };
    });
    setAutoCompleteInputZonas("");
  };

  const [errorMessages, setErrorMessages] = useState({
    especifica_fecha_hora_ocurrencia: "",
    especifica_fecha_hora_resolucion: "",
  });

  const setFile = (archivo) => {
    setEvError(!archivo && !formData.contingencia_programada  && formData.extension == "rbd"  ? true : false);
    setEvidence((prevData) => ({
      ...prevData,
      archivo,
    }));
  };

  const handleSwitch = (event) => {
    setFormData({ ...formData, ["checked"]: event.target.checked });
  };
  const handleErrorChange = (error) => {
    console.log(error);
    setErrorRut(error);
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (errorRut) {
      rutRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    const isProgramada = formData.contingencia_programada;
    const fechaInicioKey = isProgramada
      ? "programada_inicio_contingencia"
      : "especifica_fecha_hora_ocurrencia";
    const fechaFinKey = isProgramada
      ? "programada_fin_contingencia"
      : "especifica_fecha_hora_resolucion";

    const mensajes = {
      inicioInvalida: `La ${
        isProgramada ? "fecha de inicio" : "fecha de ocurrencia"
      } es inválida.`,
      inicioFuturo: `La ${
        isProgramada ? "fecha de inicio" : "fecha de ocurrencia"
      } no puede ser una fecha futura.`,
      inicioAntesDelMes: `La ${
        isProgramada ? "fecha de inicio" : "fecha de ocurrencia"
      } debe ser posterior al inicio del mes actual.`,
      finInvalida: `La ${
        isProgramada ? "fecha de término" : "fecha de solución"
      } es inválida.`,
      finAntesInicio: `La ${
        isProgramada ? "fecha de término" : "fecha de solución"
      } debe ser posterior a la ${
        isProgramada ? "fecha de inicio" : "fecha de ocurrencia"
      }.`,
      finAntesDelMes: `La ${
        isProgramada ? "fecha de término" : "fecha de solución"
      } debe ser posterior al inicio del mes actual.`,
    };

    const startOfMonth = dayjs().subtract(1, "month").startOf("month");

    // Validar fecha de inicio
    if (
      !formData[fechaInicioKey] ||
      !dayjs(formData[fechaInicioKey]).isValid()
    ) {
      errors[fechaInicioKey] = mensajes.inicioInvalida;
      valid = false;
    } else if (dayjs(formData[fechaInicioKey]).isBefore(startOfMonth)) {
      errors[fechaInicioKey] = mensajes.inicioAntesDelMes;
      valid = false;
    } else if (
      dayjs(formData[fechaInicioKey]).isAfter(dayjs()) &&
      !isProgramada
    ) {
      errors[fechaInicioKey] = mensajes.inicioFuturo;
      valid = false;
    }

    // Validar fecha de fin
    if (!formData[fechaFinKey] || !dayjs(formData[fechaFinKey]).isValid()) {
      errors[fechaFinKey] = mensajes.finInvalida;
      valid = false;
    } else if (
      dayjs(formData[fechaFinKey]).isBefore(formData[fechaInicioKey])
    ) {
      errors[fechaFinKey] = mensajes.finAntesInicio;
      valid = false;
    } else if (dayjs(formData[fechaFinKey]).isBefore(startOfMonth)) {
      errors[fechaFinKey] = mensajes.finAntesDelMes;
      valid = false;
    } else if (dayjs(formData[fechaFinKey]).isAfter(dayjs()) && !isProgramada) {
      errors[fechaFinKey] = mensajes.inicioFuturo;
      valid = false;
    }
    if (
      isProgramada &&
      dayjs(formData["programada_hora_inicio"]).isAfter(
        formData["programada_hora_termino"]
      )
    ) {
      errors["programada_hora_termino"] =
        "La hora de termino debe ser posterior a la de inicio";
      valid = false;
      timePRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else if (
      isProgramada &&
      !Object.values(formData.days).some((value) => value)
    ) {
      errors["days"] = "Debe seleccionar al menos un día";
      daysPRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      valid = false;
    } else if (!valid) {
      if (isProgramada) {
        datePRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else {
        datetimeRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    if (formData.extension === "zona" && formData.zonas.length === 0){
        errors["zonas"] = "Debe agregar al menos una zona";
        zonasRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        valid = false;
    }

    setErrorMessages(errors);
    return valid;
  };

  function getOperator() {
    axios
      .getOperator(userSesion.rut)
      .then((response) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          operator_name: response.data.operador,
          id_operador: response.data.id_operador + "",
        }));
        getAllZones(response.data.id_operador);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener el operador:", error);
        setLoading(false);
      });
  }

  function getEes() {
    if (autoCompleteInput !== "") {
      const rbd_temp = autoCompleteInput;
      axios
        .getEesData(rbd_temp, "1")
        .then((response) => {
          const tempRep =
            response.data.nombres_representantes.length === 1
              ? [response.data.nombres_representantes[0], "Sin representante"]
              : response.data.nombres_representantes;

            const tempEmails = response.data.email_representantes.length === 1
              ? [response.data.email_representantes[0], ""]
              : response.data.email_representantes;

              

          setFormData((prevFormData) => ({
            ...prevFormData,
            nombre_colegio: response.data.nombre_colegio,
            ees_managers:
              tempRep.length > 0
                ? tempRep
                : ["Sin representante", "Sin representante"],
            ees_managers_email:
              tempEmails.length > 0
                ? tempEmails
                : ["", ""],
            ees_temporal_manager_list:
              response.data.nombres_representantes_temporales.length > 0
                ? response.data.nombres_representantes_temporales
                : ["Sin representante"],
            id_zona: response.data.id_zona + "",
          }));
          setError(false);
          setErrorMsg("");
        })
        .catch((error) => {
          console.error("Error al obtener datos del EES:", error);

          setFormData((prevFormData) => ({
            ...prevFormData,
            nombre_colegio: "Colegio no encontrado",
            ees_managers: ["Colegio no encontrado", "Colegio no encontrado"],
            ees_temporal_manager_list: ["Sin representante"],
            id_zona: "",
          }));

          setError(true);
          setErrorMsg("Colegio no encontrado");
        });
    }
  }

  const save = async (event) => {
    event.preventDefault();
    if (loadingButton) return;
    setLoadingButton(true);

    if (!submitConfirm) {
      setSubmitConfirm(!submitConfirm);
      setLoadingButton(false);
      return;
    }

    if (!evidence && !formData.contingencia_programada && formData.extension == "rbd") {
      setEvError(!evidence && !formData.contingencia_programada && formData.extension == "rbd" ? true : false);
      evidenceRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoadingButton(false);
      return;
    }
    if (error) {
      autoCompleteRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoadingButton(false);
      return;
    }

    setLoading(true);

    if (validateForm()) {
      handleDownloadPdf();
    } else {
      setLoading(false);
      setLoadingButton(false);
    }
  };

  const handleDownloadPdf = () => {
    const element = contingenciasRef.current;
    setLoadingPdf(true);

    setTimeout(() => {
      const element = contingenciasRef.current;

      html2canvas(element, { scale: 2 })
        .then((canvas) => {
          setLoading(true);
          const imageData = canvas.toDataURL("image/jpeg", 1);

          const pdfWidth = 210;
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
          const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight + 7]);

          pdf.setFillColor(255, 255, 255);
          pdf.rect(0, 0, pdfWidth, pdfHeight + 140, "F");
          pdf.addImage(imageData, "JPEG", 0, 0, pdfWidth, pdfHeight);

          const pdfBlob = pdf.output("blob");

          const form = new FormData();
          const localFormData = {
            ...formData,
            rbd: autoCompleteInput ? autoCompleteInput : "",
            especifica_fecha_hora_ocurrencia: formData[
              "especifica_fecha_hora_ocurrencia"
            ]
              ? formData["especifica_fecha_hora_ocurrencia"]
                  .locale("es")
                  .format("YYYY/MM/DD HH:mm:ss")
                  .toString()
              : null,
            especifica_fecha_hora_resolucion: formData[
              "especifica_fecha_hora_resolucion"
            ]
              ? formData["especifica_fecha_hora_resolucion"]
                  .locale("es")
                  .format("YYYY/MM/DD HH:mm:ss")
                  .toString()
              : null,
            programada_inicio_contingencia: formData[
              "programada_inicio_contingencia"
            ]
              ? formData["programada_inicio_contingencia"]
                  .locale("es")
                  .format("YYYY/MM/DD")
                  .toString()
              : null,
            programada_fin_contingencia: formData["programada_fin_contingencia"]
              ? formData["programada_fin_contingencia"]
                  .locale("es")
                  .format("YYYY/MM/DD")
                  .toString()
              : null,
            programada_hora_inicio: formData["programada_hora_inicio"]
              ? formData["programada_hora_inicio"]
                  .locale("es")
                  .format("HH:mm:ss")
                  .toString()
              : null,
            programada_hora_termino: formData["programada_hora_termino"]
              ? formData["programada_hora_termino"]
                  .locale("es")
                  .format("HH:mm:ss")
                  .toString()
              : null,
            evidence_list:
              evidence && evidence.archivo ? [evidence.archivo] : [],
          };

          form.append("data", JSON.stringify(localFormData));

          for (let i = 0; i < localFormData["evidence_list"].length; i++) {
            form.append("evidence", localFormData["evidence_list"][i]);
          }

          form.append("file", pdfBlob, "temp.pdf");

          axios
            .sendFilesContingencias(form)
            .then((response) => {
              setLoading(false);
              //setSubmitConfirm(!submitConfirm)
              setModalError(false);
              setModalContent("Documento enviado y firmado");
              setOpen(true);
            })
            .catch((error) => {
              setModalError(true);
              setModalError("La creación del documento falló");
              setLoading(false);
            });
          setLoadingPdf(false);
          setSubmitConfirm(false);
          setLoadingButton(false);
        })
        .catch((error) => {
          console.error("Error generando el PDF:", error);
          setLoadingPdf(false);
          setLoading(false);
          setSubmitConfirm(false);
          setLoadingButton(false);
        });
    }, 0);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      days: {
        ...prevFormData.days,
        [name]: checked,
      },
    }));
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      days: null,
    }));
  };

  function getRBD() {
    if (autoCompleteInput !== "") {
      let rbd_temp = autoCompleteInput;
      setRbdLoading(true);
      axios
        .getRBDOperador(rbd_temp, formData.id_operador)
        .then((response) => {
          setRBDList(response.data.rbd_list);
          setRbdLoading(false);
          setError(false);
          setErrorMsg("");
          getEes();
        })
        .catch((error) => {
          setRbdLoading(true);
          setError("Colegio no encontrado");
        });
    }
  }

  useEffect(() => {
    setFormData({
      ...formData,
      rbd: autoCompleteInput ? autoCompleteInput : "",
    });
    getRBD();
  }, [autoCompleteInput]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    getOperator();
    setDrawerHidding(false);
  }, [setPageTitle, setDrawerHidding]);

  return (
    <>
      <PageBody>
        <PageBgBlock pad="0">
          {loading ? (
            <HourglassLoader />
          ) : (
            <>
              <Box
                sx={{
                  paddingLeft: "9rem",
                  paddingRight: "9rem",
                  paddingTop: "2rem",
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{ marginBottom: "0.24rem" }}
                >
                  LEVANTAMIENTO DE CONTINGENCIA
                </Typography>
                <Stack alignItems="left" justifyContent="center" spacing={2}>
                  <Box
                    justifyContent="center"
                    sx={{ display: "flex", margin: "0.2rem!important" }}
                  >
                    <Typography
                      variant="small"
                      color="inherit"
                      sx={{
                        textAlign: "center",
                        justifyContent: "center",
                        paddingY: "0",
                        marginTop: "0",
                      }}
                    >
                      N° folio:{" "}
                      <Typography
                        variant="small"
                        sx={{
                          paddingLeft: "1rem",
                          marginTop: "0",
                          color: "#FFF",
                        }}
                      >
                        {String("").padStart(8, "0")}
                      </Typography>
                    </Typography>
                  </Box>

                  <Box
                    justifyContent="center"
                    sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                  >
                    <Typography
                      variant="body"
                      align="center"
                      sx={{ marginBottom: "2rem" }}
                    >
                      Usted está en el Formulario para el levantamiento de
                      contingencias, aquí podrá informar en caso de algún
                      problema del servicio de conectividad que no sea de su
                      responsabilidad.
                    </Typography>
                  </Box>
                </Stack>

                <form onSubmit={save} id="contingencia">
                  <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
                    <Typography
                      variant="h3"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      DATOS DEL EMISOR
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            disabled
                            required
                            name="rut"
                            label="Rut Operador Administrador"
                            size="small"
                            value={formData.rut_emisor}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            disabled
                            required
                            name="nombre"
                            label="Nombre y Apellido"
                            size="small"
                            value={formData.name_emisor}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Typography
                      variant="h3"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      DATOS DEL OPERADOR
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            disabled
                            required
                            name="id_operador"
                            label="ID Operador"
                            size="small"
                            value={formData.id_operador}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            disabled
                            required
                            name="operator_name"
                            label="Nombre Operador"
                            size="small"
                            value={formData.operator_name}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Typography
                      variant="h3"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      DATOS RBD O ZONA
                    </Typography>

                    <TabContext value={formData.extension}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                          variant="fullWidth"
                        >
                          <Tab label="Por RBD" value="rbd" />
                          <Tab label="Por Zona" value="zona" />
                        </TabList>
                      </Box>
                      <TabPanel value="rbd" sx={{ padding: 0, margin: 0 }}>
                        <Grid container spacing={2}>
                          <Grid size={6}>
                            <Box ref={autoCompleteRef}>
                              <FormControl sx={{ minWidth: "100%" }}>
                                <Autocomplete
                                  key="rbd"
                                  value={autoCompleteInput}
                                  onChange={(event, newValue) => {
                                    setAutoCompleteInput(
                                      newValue ? newValue : ""
                                    );
                                    setFormData({
                                      ...formData,
                                      rbd: newValue ? newValue : "",
                                    });
                                  }}
                                  inputValue={autoCompleteInput}
                                  onInputChange={(event, newValue) => {
                                    setAutoCompleteInput(
                                      newValue ? newValue : ""
                                    );
                                    setFormData({
                                      ...formData,
                                      rbd: newValue ? newValue : "",
                                    });
                                  }}
                                  disablePortal
                                  loading={rbdLoading}
                                  loadingText="Cargando..."
                                  multiple={false}
                                  options={RBDList}
                                  //blurOnSelect
                                  autoComplete
                                  size="small"
                                  //onBlur={getEes}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        required={formData.extension === "rbd"}
                                        //onChange={(event) => handleAutoRBDchange(event, 1)}
                                        error={error}
                                        helperText={errorMsg}
                                        {...params}
                                        label="RBD"
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid size={6}>
                            <FormControl fullWidth variant="outlined">
                              <TextField
                                label="ID Zona"
                                size="small"
                                disabled
                                value={formData.id_zona}
                                sx={{ width: "100%" }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid size={12}>
                            <FormControl fullWidth variant="outlined">
                              <TextField
                                id="outlined"
                                label="Nombre del Colegio"
                                size="small"
                                disabled
                                value={formData.nombre_colegio}
                                sx={{ width: "100%" }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid size={6}>
                            <TextField
                              label="Representante del establecimiento 1"
                              size="small"
                              disabled
                              value={formData.ees_managers[0]}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid size={6}>
                            <TextField
                              label="Representante del establecimiento 2"
                              size="small"
                              disabled
                              value={formData.ees_managers[1]}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid size={6}>
                            <TextField
                              label="Email Representante del establecimiento 1"
                              size="small"
                              disabled
                              value={formData.ees_managers_email[0]}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid size={6}>
                            <TextField
                              label="Email Representante del establecimiento 2"
                              size="small"
                              disabled
                              value={formData.ees_managers_email[1]}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid sx={{ width: "100%", marginY: 2 }} size={12}>
                            <Box ref={rutRef}>
                              <InputRut
                                error={errorRut}
                                errorRut={handleErrorChange}
                                checked={formData.checked}
                                rut={formData.ees_temporal_manager}
                                email={formData.ees_temporal_manager_email}
                                handleSwitch={handleSwitch}
                                handleRut={(newValue) =>
                                  setFormData({
                                    ...formData,
                                    ["ees_temporal_manager"]: newValue,
                                  })
                                }
                                handleEmail={(newValue) =>
                                  setFormData({
                                    ...formData,
                                    ["ees_temporal_manager_email"]: newValue,
                                  })
                                }
                                margin="0"
                              ></InputRut>
                            </Box>
                          </Grid>
                        </Grid>

                        <FormControl sx={{ width: "100%", spacing: 2 }}>
                          <Grid
                            container
                            sx={{
                              width: "100%",
                              background: formData.contingencia_programada
                                ? "#BBDEFB"
                                : "#E4E4E4",
                              borderRadius: "0.5rem",
                              padding: "2%",
                            }}
                            spacing={2}
                          >
                            <Grid sx={{ width: "100%" }} size={12}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={formData.contingencia_programada}
                                    onChange={() => {
                                      setFormData({
                                        ...formData,
                                        contingencia_programada:
                                          !formData.contingencia_programada,
                                      });
                                      setEvError(false);
                                    }}
                                  />
                                }
                                label="CONTINGENCIA PROGRAMADA"
                              />
                            </Grid>
                            <Grid size={12}>
                              <FormControl
                                sx={{ width: "100%", maxWidth: "100%" }}
                              >
                                <TextField
                                  disabled={!formData.contingencia_programada}
                                  required
                                  multiline
                                  minRows={4}
                                  maxRows={30}
                                  id="outlined-required"
                                  label="Descripción de contingencia"
                                  placeholder="Descripción de contingencia"
                                  size="small"
                                  value={formData.programada_descripcion}
                                  onChange={(event) =>
                                    setFormData({
                                      ...formData,
                                      programada_descripcion:
                                        event.target.value,
                                    })
                                  }
                                />
                              </FormControl>
                            </Grid>

                            <Grid size={12}>
                              <Typography size="body">
                                Rango horario de ocurrencia
                              </Typography>
                              <Divider></Divider>
                            </Grid>

                            <Grid size={6} ref={timePRef}>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                              >
                                <TimePicker
                                  disabled={!formData.contingencia_programada}
                                  sx={{ width: "100%" }}
                                  label="Hora de inicio"
                                  value={formData.programada_hora_inicio}
                                  onChange={(newValue) =>
                                    setFormData({
                                      ...formData,
                                      programada_hora_inicio: newValue,
                                    })
                                  }
                                  minTime={dayjs().startOf("day")}
                                  minutesStep={1}
                                  slotProps={{
                                    textField: {
                                      required: true,
                                      size: "small",
                                      helperText:
                                        errorMessages.programada_hora_inicio,
                                      error:
                                        !!errorMessages.programada_hora_inicio,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>

                            <Grid size={6}>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                              >
                                <TimePicker
                                  disabled={!formData.contingencia_programada}
                                  sx={{ width: "100%" }}
                                  label="Hora de término"
                                  value={formData.programada_hora_termino}
                                  minutesStep={1}
                                  onChange={(newValue) =>
                                    setFormData({
                                      ...formData,
                                      programada_hora_termino: newValue,
                                    })
                                  }
                                  minTime={formData.programada_hora_inicio}
                                  slotProps={{
                                    textField: {
                                      required: true,
                                      size: "small",
                                      helperText:
                                        errorMessages.programada_hora_termino,
                                      error:
                                        !!errorMessages.programada_hora_termino,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>

                            <Grid size={12}>
                              <Typography size="body">
                                Repetición de la ocurrencia
                              </Typography>
                              <Divider></Divider>
                            </Grid>

                            <Grid size={12} ref={daysPRef}>
                              <Grid container spacing={2} columns={14}>
                                {DAYS.map((dia) => (
                                  <Grid size={2} key={dia}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled={
                                            !formData.contingencia_programada
                                          }
                                          checked={formData.days[dia]}
                                          onChange={handleCheckboxChange}
                                          name={dia}
                                        />
                                      }
                                      label={dia}
                                    />
                                  </Grid>
                                ))}
                              </Grid>

                              {!!errorMessages.days && (
                                <Grid size={12}>
                                  <Typography color="error" size="small">
                                    {errorMessages.days}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>

                            <Grid size={12}>
                              <Typography size="body">
                                Periodo que debe durar la contingencia
                                programada
                              </Typography>
                              <Divider></Divider>
                            </Grid>

                            <Grid size={6} ref={datePRef}>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"es"}
                              >
                                <DatePicker
                                  disabled={!formData.contingencia_programada}
                                  sx={{ width: "100%" }}
                                  label="Fecha de inicio periodo"
                                  value={
                                    formData.programada_inicio_contingencia
                                  }
                                  onChange={(newValue) =>
                                    setFormData({
                                      ...formData,
                                      programada_inicio_contingencia: newValue,
                                    })
                                  }
                                  minDate={dayjs()
                                    .subtract(1, "month")
                                    .startOf("month")}
                                  slotProps={{
                                    textField: {
                                      required: true,
                                      size: "small",
                                      helperText:
                                        errorMessages.programada_inicio_contingencia,
                                      error:
                                        !!errorMessages.programada_inicio_contingencia,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid size={6}>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"es"}
                              >
                                <DatePicker
                                  disabled={!formData.contingencia_programada}
                                  sx={{ width: "100%" }}
                                  label="Fecha de término periodo"
                                  value={formData.programada_fin_contingencia}
                                  onChange={(newValue) =>
                                    setFormData({
                                      ...formData,
                                      programada_fin_contingencia: newValue,
                                    })
                                  }
                                  minDate={
                                    formData.programada_inicio_contingencia
                                  }
                                  slotProps={{
                                    textField: {
                                      required: true,
                                      size: "small",
                                      helperText:
                                        errorMessages.programada_fin_contingencia,
                                      error:
                                        !!errorMessages.programada_fin_contingencia,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </TabPanel>
                      <TabPanel value="zona" sx={{ margin: 0, padding: 0 }}>
                        <Grid container spacing={2}>
                          <Grid size={9}>
                            <FormControl sx={{ minWidth: "100%" }}>
                              <Autocomplete
                                value={
                                  zonas.find(
                                    (zona) =>
                                      zona.nombre_zona ===
                                      autoCompleteInputZonas
                                  ) || null
                                }
                                onChange={(event, newValue) => {
                                  console.log(newValue);
                                  setAutoCompleteInputZonas(
                                    newValue ? newValue.nombre_zona : ""
                                  );
                                  if (newValue) {
                                    setZonaData(newValue);
                                  }
                                }}
                                disablePortal
                                loading={rbdLoading}
                                loadingText="Cargando..."
                                options={zonas || []}
                                getOptionLabel={(option) =>
                                  option?.nombre_zona || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option?.id_zona === value?.id_zona
                                }
                                size="small"
                                autoComplete
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={error}
                                    helperText={errorMsg}
                                    label="Zona"
                                    placeholder="Buscar zona"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          {params.InputProps.startAdornment}
                                          <TuneIcon />
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item size={3}>
                            <PageButtonSimple
                              pad=".5rem"
                              width="auto"
                              onClick={handleAddRowZonas}
                              startIcon={<AddCircleOutlineOutlinedIcon />}
                              disabled={!autoCompleteInputZonas || rbdLoading}
                            >
                              Agregar a la lista
                            </PageButtonSimple>
                          </Grid>

                          <Grid size={12}>
                            <TableContainer
                              component={Paper}
                              sx={{ mb: 2, mt: 2 }}
                            >
                              <Table ref={zonasRef}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ width: "80px" }}>
                                      <IconButton
                                        onClick={() =>
                                          setFormData((prevState) => ({
                                            ...prevState,
                                            zonas: [],
                                          }))
                                        } // Limpia solo las zonas
                                      >
                                        <RemoveCircleOutlineIcon />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell>Id Zona</TableCell>
                                    <TableCell>RBDs</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {formData.zonas.length > 0 ? (
                                    formData.zonas.map((zona, index) => (
                                      <TableRow key={index}>
                                        <TableCell>
                                          <IconButton
                                            onClick={() =>
                                              handleDeleteRow(index)
                                            }
                                          >
                                            <RemoveCircleOutlineIcon />
                                          </IconButton>
                                        </TableCell>
                                        <TableCell
                                          sx={{ verticalAlign: "middle" }}
                                        >
                                          {zona.id_zona}
                                        </TableCell>
                                        <TableCell
                                          sx={{ verticalAlign: "middle" }}
                                        >
                                          {zona.rbd_list.join(", ")}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={4} align="center">
                                        No hay zonas agregadas
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </TabContext>

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      CONTINGENCIA {formData.extension === "rbd" ? "ESPECÍFICA" : "POR ZONAS"}
                    </Typography>

                    <Grid container spacing={2} ref={datetimeRef}>
                      <Grid size={6}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={"es"}
                        >
                          <DateTimePicker
                            disabled={formData.contingencia_programada}
                            sx={{ width: "100%" }}
                            label="Fecha y hora de ocurrencia"
                            value={formData.especifica_fecha_hora_ocurrencia}
                            onChange={(newValue) =>
                              setFormData({
                                ...formData,
                                especifica_fecha_hora_ocurrencia: newValue,
                              })
                            }
                            minDate={dayjs()
                              .subtract(1, "month")
                              .startOf("month")}
                            slotProps={{
                              textField: {
                                required: true,
                                size: "small",
                                helperText:
                                  errorMessages.especifica_fecha_hora_ocurrencia,
                                error:
                                  !!errorMessages.especifica_fecha_hora_ocurrencia,
                              },
                            }}
                            disableFuture
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid size={6}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={"es"}
                        >
                          <DateTimePicker
                            disabled={formData.contingencia_programada}
                            sx={{ width: "100%" }}
                            label="Fecha y hora de resolución"
                            value={formData.especifica_fecha_hora_resolucion}
                            onChange={(newValue) =>
                              setFormData({
                                ...formData,
                                especifica_fecha_hora_resolucion: newValue,
                              })
                            }
                            minDate={formData.especifica_fecha_hora_ocurrencia}
                            slotProps={{
                              textField: {
                                required: true,
                                size: "small",
                                helperText:
                                  errorMessages.especifica_fecha_hora_resolucion,
                                error:
                                  !!errorMessages.especifica_fecha_hora_resolucion,
                              },
                            }}
                            disableFuture
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                          <InputLabel
                            id="especifica_origen_falla-label"
                            sx={{ backgroundColor: "#fff" }}
                          >
                            Origen de la falla
                          </InputLabel>
                          <Select
                            disabled={formData.contingencia_programada}
                            labelId="especifica_origen_falla-label"
                            id="especifica_origen_falla"
                            value={formData.especifica_origen_falla}
                            label="Origen de la falla"
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={(event) =>
                              setFormData({
                                ...formData,
                                especifica_origen_falla: event.target.value,
                              })
                            }
                          >
                            <MenuItem value={"Falla interna al adjudicatario"}>
                              Falla interna al adjudicatario
                            </MenuItem>
                            <MenuItem value={"Falla externa al adjudicatario"}>
                              Falla externa al adjudicatario
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                          <InputLabel
                            id="especifica_extension_falla-label"
                            sx={{ backgroundColor: "#fff" }}
                          >
                            Extensión de la falla
                          </InputLabel>
                          <Select
                            disabled={formData.contingencia_programada || formData.extension === "zona"}
                            labelId="especifica_extension_falla-label"
                            id="especifica_extension_falla"
                            value={formData.especifica_extension_falla}
                            label="Extensión de la falla"
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={(event) =>
                              setFormData({
                                ...formData,
                                especifica_extension_falla: event.target.value,
                              })
                            }
                          >
                            <MenuItem value={"Solo EES"}>Solo EES</MenuItem>
                            <MenuItem value={"Evento mayor"}>
                              Evento mayor
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid size={12}>
                        <FormControl sx={{ width: "100%" }} size="small">
                          <InputLabel
                            id="especifica_codigo_evento-label"
                            sx={{ backgroundColor: "#fff" }}
                          >
                            Código de evento
                          </InputLabel>
                          <Select
                            disabled={formData.contingencia_programada}
                            labelId="especifica_codigo_evento-label"
                            id="especifica_codigo_evento"
                            value={formData.especifica_codigo_evento}
                            label="Código de evento"
                            size="small"
                            sx={{ width: "100%" }}
                            onChange={(event) =>
                              setFormData({
                                ...formData,
                                especifica_codigo_evento: event.target.value,
                              })
                            }
                          >
                            <MenuItem value={"1"}>
                              1 - Falla física equipo de datos cliente
                            </MenuItem>
                            <MenuItem value={"2"}>
                              2 - Bloqueo equipo de datos cliente
                            </MenuItem>
                            <MenuItem value={"3"}>
                              3 - Corte enlace físico (Fibra óptica o cobre)
                            </MenuItem>
                            <MenuItem value={"4"}>
                              4 - Corte enlace microondas
                            </MenuItem>
                            <MenuItem value={"5"}>
                              5 - Caída plataforma satelital
                            </MenuItem>
                            <MenuItem value={"6"}>
                              6 - Caída enlace internacional
                            </MenuItem>
                            <MenuItem value={"7"}>
                              7 - Falla nodo de red
                            </MenuItem>
                            <MenuItem value={"8"}>
                              8 - Falla red de transporte
                            </MenuItem>
                            <MenuItem value={"9"}>
                              9 - Error de configuración firewall
                            </MenuItem>
                            <MenuItem value={"10"}>
                              10 - Error de configuración DNS
                            </MenuItem>
                            <MenuItem value={"11"}>
                              11 - Error de configuración otro nodo de servicio
                            </MenuItem>
                            <MenuItem value={"12"}>
                              12 - Falla de equipo firewall
                            </MenuItem>
                            <MenuItem value={"13"}>
                              13 - Falla de equipo DNS
                            </MenuItem>
                            <MenuItem value={"14"}>
                              14 - Falla de equipo otro nodo de servicio
                            </MenuItem>
                            <MenuItem value={"15"}>
                              15 - Corte de energía EES
                            </MenuItem>
                            <MenuItem value={"16"}>
                              16 - Corte suministro público energía
                            </MenuItem>
                            <MenuItem value={"17"}>
                              17 - Otro responsabilidad del operador
                            </MenuItem>
                            <MenuItem value={"18"}>
                              18 - Otro responsabilidad de terceros
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {!loadingPdf ? (
                      <>
                        <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
                          <TextField
                            required={!formData.contingencia_programada}
                            disabled={formData.contingencia_programada}
                            multiline
                            minRows={4}
                            maxRows={30}
                            id="outlined-required"
                            label="Descripción de falla"
                            placeholder="Descripción de falla"
                            size="small"
                            value={formData.especifica_descripcion_falla}
                            onChange={(event) =>
                              setFormData({
                                ...formData,
                                especifica_descripcion_falla:
                                  event.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </>
                    ) : (
                      <>
                        <InputFake
                          value={formData.especifica_descripcion_falla}
                        ></InputFake>
                      </>
                    )}

                    <Box ref={evidenceRef}>
                      <InputFile
                        onFileSelect={setFile}
                        fileType="pdf"
                        placeholder="Subir evidencia (formato PDF)"
                      />
                      <Typography
                        variant="small"
                        align="left"
                        sx={{
                          marginBottom: "18px",
                          color: evError ? "red" : "#111",
                        }}
                      >
                        {evError
                          ? "Debe adjuntar evidencia"
                          : "Máximo 25mb"}
                      </Typography>
                    </Box>
                  </Stack>
                </form>
              </Box>
              <Stack
                sx={{
                  paddingLeft: "9rem",
                  paddingRight: "9rem",
                  paddingBottom: "3rem",
                }}
              >
                <Grid>
                  {submitConfirm ? (
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size={12}
                      sx={{ paddingBottom: "3rem" }}
                    >
                      <Alert
                        icon={false}
                        variant="filled"
                        severity="warning"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          "& .MuiAlert-message": {
                            textAlign: "center",
                            width: "inherit",
                          },
                        }}
                      >
                        <Typography
                          variant="body"
                          color="inherit"
                          sx={{ textAlign: "center" }}
                        >
                          Revise que todos los campos tienen la información
                          correcta antes de enviar el levantamiento de
                          Contingencia, al clickear “Firmar y enviar” el
                          documento queda firmado por el operador.
                        </Typography>
                      </Alert>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                <PageButtonBlack form="contingencia" loading={loadingButton}>
                  {" "}
                  Firmar y enviar
                </PageButtonBlack>
              </Stack>
            </>
          )}
        </PageBgBlock>
        <ModalStandard
          open={open}
          error={modalError}
          title={modalContent}
          btnText="Ir al listado"
          onClick={() => navigate("/contingencias_lista")}
          onClose={handleClose}
        ></ModalStandard>
      </PageBody>
      {loadingPdf ? (
        <Box
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            position: "absolute",
            width: "1200px",
            height: "0",
          }}
        >
          <Box ref={contingenciasRef} sx={{ padding: "12px" }}>
            <ContingenciasHiddenPreview
              formData={formData}
              evidence={evidence}
            ></ContingenciasHiddenPreview>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from '@mui/material/Divider';

import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import SchoolIcon from '@mui/icons-material/School';

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import PageCoverImg from "../GeneralComponents/PageCoverImg";
import EmbeddedOperadorGrid from "./EmbeddedOperador";
import EmbeddedMineducGrid from "./EmbeddedMineduc";
import EmbeddedSubtelGrid from "./EmbeddedSubtel";
import EmbeddedAdminGrid from "./EmbeddedAdmin";

import EditIcon from "@mui/icons-material/Edit";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { ReactComponent as DocSign } from "./doc_sign.svg";
import { ROL_MINEDUC_ADMIN, ROL_REP_EES, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_CON_MINEDUC, ROL_OP_ADMIN, ROL_OP_INS, ROL_ADMIN} from "../../Constants/Constants";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: '20px',
  boxShadow: 'none',
  padding: '0',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#FFFFFF',
  }),
}));

export default function Home() {
  const { includeRol, userSesion } = useContext(GeneralContext);
  const [avatarSrc, setAvatarSrc] = useState("");
  const navigate = useNavigate();
  const current_date = new Date();
  const [eesData, setEesData] = useState(null);

  const options = { month: "long" };
  const month = current_date.toLocaleDateString("es-ES", options).toUpperCase();

const getJornadas = () => {
    const jornadas = Object.keys(eesData)
      .filter((key) => key.startsWith('jornada_') && eesData[key])
      .map((key) => key.split('_')[1].toUpperCase()); 

    if (jornadas.length === 0) {
      return '-';
    }

    return jornadas.join(' - ');
  };

  const defaultAvatar = "/assets/operadores/default.png";
  const handleError = (e) => {
    if (e.target.src !== defaultAvatar) {
      setAvatarSrc(defaultAvatar);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (includeRol([ROL_REP_EES]) && !eesData) {
        axios
          .getEesByRut(userSesion.rut)
          .then((response) => {
            setAvatarSrc(`/assets/operadores/${response.data.id_operador}.png`)
            setEesData(response.data);
          })
          .catch((error) => {
          });
      }
    };
    fetchData();
  }, []);

  const renderGrid = () => {
  if (includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL])) {
    return <EmbeddedSubtelGrid />;
  } else if (includeRol([ROL_CON_MINEDUC, ROL_MINEDUC_ADMIN])) {
    return <EmbeddedMineducGrid />;
  } else if (includeRol([ROL_OP_ADMIN])) {
    return <EmbeddedOperadorGrid />;
  } else if (includeRol([ROL_ADMIN])) {
    return <EmbeddedAdminGrid />;
  }
  return null; 
};

  return (


    <PageBody>
      <Stack spacing={4}>
        { includeRol([ROL_OP_INS]) && (<Grid container spacing={2} sx={{ paddingBottom: "15px" }}>
          <Grid size={6}>
            <PageButtonBlack
              onClick={() => navigate("/fas")}
              icon={<EditIcon />}
              width="100%"
              pad="10px 18px"
            >
              Crear nuevo FAS
            </PageButtonBlack>
          </Grid>
          <Grid size={6}>
            <PageButtonWhite
              onClick={() => navigate("/fus")}
              icon={<EditIcon />}
              width="100%"
              pad="10px 18px"
            >
              Crear nuevo FUS
            </PageButtonWhite>
          </Grid>
        </Grid>
      )}
      
        { includeRol([ROL_REP_EES]) && (<Grid container spacing={2} sx={{ paddingBottom: "15px" }}>
          <Grid size={3}>
            <PageButtonBlack
              onClick={() => navigate("/fas_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FAS
            </PageButtonBlack>
          </Grid>
          <Grid size={3}>
            <PageButtonBlack
              onClick={() => navigate("/fus_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FUS
            </PageButtonBlack>
          </Grid>
          <Grid size={6}>
            <PageButtonWhite
              onClick={() => navigate("/contingencias_lista")}
              width="100%"
              pad="10px 18px"
            >
              Contingencias
            </PageButtonWhite>
          </Grid>
        </Grid>
        )}

        { includeRol([ROL_ADMIN_SUBTEL, ROL_MINEDUC_ADMIN]) && (<Grid container spacing={2} sx={{ paddingBottom: "15px" }}>
          <Grid size={6}>
            <PageButtonBlack
              onClick={() => window.open("/service/grafana/d/be0kjiscwhzwga/reporte-supervision", "_blank")}
              width="100%"
              pad="10px 18px"
            >
              Monitoreo en línea
            </PageButtonBlack>
          </Grid>
          <Grid size={3}>
            <PageButtonWhite
              onClick={() => navigate("/fas_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FAS
            </PageButtonWhite>
          </Grid>
          <Grid size={3}>
            <PageButtonWhite
              onClick={() => navigate("/fus_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FUS
            </PageButtonWhite>
          </Grid>
        </Grid>
        )}


        { includeRol([ROL_OP_ADMIN]) && (<Grid container spacing={2} sx={{ paddingBottom: "15px" }}>
          <Grid size={6}>
            <PageButtonBlack
              onClick={() => navigate("/prefactura_detalle")}
              width="100%"
              pad="10px 18px"
            >
              Validación pre-facturas
            </PageButtonBlack>
          </Grid>
          <Grid size={3}>
            <PageButtonWhite
              onClick={() => navigate("/contingencias_lista")}
              width="100%"
              pad="10px 18px"
            >
              Contingencias
            </PageButtonWhite>
          </Grid>
          <Grid size={3}>
            <PageButtonWhite
              onClick={() => navigate("/fas_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FAS
            </PageButtonWhite>
          </Grid>
        </Grid>
        )}


        { includeRol([ROL_VAL_SUBTEL]) && (<Grid container spacing={2} sx={{ paddingBottom: "15px" }}>
          <Grid size={6}>
            <PageButtonBlack
              onClick={() => navigate("/prefactura")}
              width="100%"
              pad="10px 18px"
            >
              Validación pre-facturas
            </PageButtonBlack>
          </Grid>
          <Grid size={3}>
            <PageButtonWhite
              onClick={() => navigate("/contingencias_lista")}
              width="100%"
              pad="10px 18px"
            >
              Contingencias
            </PageButtonWhite>
          </Grid>
          <Grid size={3}>
            <PageButtonWhite
              onClick={() => navigate("/parque")}
              width="100%"
              pad="10px 18px"
            >
              Modificar Estado de Parque
            </PageButtonWhite>
          </Grid>
        </Grid>
        )}


      {renderGrid()}

        { includeRol([ROL_REP_EES]) && eesData && avatarSrc !== "" &&  (
                  <Grid container spacing={2}>
  <Grid size={8}>
    <Item sx={{ height: '100%'}}>

    <Box sx={{  padding: "10px 0 20px", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"  }}>
      <Box >
        <Typography variant="h3" align="center">{eesData.nombre_colegio}</Typography>
        <Typography variant="h6" align="center" sx={{ fontFamily: "Roboto", fontWeight: "500" }}>
          RBD: <b>{eesData.rbd}</b>
        </Typography>
        <Divider sx={{ margin: "1rem 0", borderColor: "#F3F3F3" }} />

        {/* Grid con datos del establecimiento */}
        <Grid container>
          <Grid size={6}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem disablePadding>
                <ListItemAvatar>
                  <Avatar sx={{ color: "#111", backgroundColor: "transparent" }}>
                    <SchoolIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h5">Sostenedor</Typography>}
                  secondary={<Typography variant="body2">{eesData.sostenedor || "Desconocido"}</Typography>}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid size={6}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem disablePadding>
                <ListItemAvatar>
                  <Avatar sx={{ color: "#111", backgroundColor: "transparent" }}>
                    <PhoneOutlinedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h5">Teléfono</Typography>}
                  secondary={<Typography variant="body2">{eesData.telefono || "Desconocido"}</Typography>}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid size={6}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem disablePadding>
                <ListItemAvatar>
                  <Avatar sx={{ color: "#111", backgroundColor: "transparent" }}>
                    <OtherHousesOutlinedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h5">Dirección</Typography>}
                  secondary={<Typography variant="body2">{eesData.direccion || "Desconocido"}</Typography>}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid size={6}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem disablePadding>
                <ListItemAvatar>
                  <Avatar sx={{ color: "#111", backgroundColor: "transparent" }}>
                    <MailOutlinedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h5">E-mail</Typography>}
                  secondary={<Typography variant="body2">{eesData.email || "Desconocido"}</Typography>}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Grid container sx={{ paddingTop: 2 }}>
          <Grid size={2}></Grid>
          <Grid size={8}>
            <PageButtonBlack onClick={() => navigate("/establecimiento_modificar")} width="100%" pad="10px 18px">
              Editar datos del establecimiento
            </PageButtonBlack>
          </Grid>
          <Grid size={2}></Grid>
        </Grid>
      </Box>
    </Box>

    </Item>
  </Grid>
  <Grid size={4}>
    <Stack spacing={2}>
      <Item sx={{ heigth: "50%"}}>
      <PageBgBlock pad="30px 0 50px" sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography variant="h5" align="center">Operador</Typography>
        <Divider sx={{ margin: "1.5rem 0", borderColor: "#F3F3F3" }} />
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <Avatar
              alt="operador_logo" 
              src={avatarSrc}
              onError={handleError}
              sx={{ width: "3rem", height: "3rem", marginBottom: 2, "& img": { objectFit: "contain" }}}
            />
          <Typography variant="h4" align="center" sx={{ fontFamily: "Roboto", marginBottom: "-1rem" }}>
            {eesData.nombre_operador || "Desconocido"}
          </Typography>
        </Box>
      </PageBgBlock></Item>
       <Item sx={{ heigth: "50%", minHeigth: "400px"}}><PageBgBlock pad="30px 0 50px" sx={{  flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography variant="h5" align="center">Tecnología</Typography>
        <Divider sx={{ margin: "1.5rem 0", borderColor: "#F3F3F3" }} />
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <Typography variant="h3" align="center" sx={{ fontFamily: "Roboto"}}>
            {eesData.tecnologia || "Desconocido"}
          </Typography>
        </Box>
      </PageBgBlock></Item>
     
    </Stack>
  </Grid>
</Grid>
        )}





        <PageBgBlock pad="0 0 0 1.5rem">
          <Grid container spacing={0}>
            <Grid size={8}>
              <PageBgBlock pad="2rem ">
              <Typography variant="h1" align="left" sx={{ marginBottom: "1rem"}}>SAGEC</Typography>
                 <Box justifyContent="center" sx={{maxWidth: "100%", margin: '0 auto'}}>
                  <Typography variant="body1"  align="left" sx={{ marginBottom: "1.5rem"}}>
                 El Servicio de Administración y Gestión de Conectividad (SAGEC), es una plataforma de apoyo en el seguimiento de la gestión técnica y comercial del Servicio de Transmisión de Datos, provisto por las Beneficiaras del Proyecto "Conectividad para la Educación 2030".
                  </Typography>
                </Box>
              </PageBgBlock>
            </Grid>
            <Grid size={4}>
              <PageCoverImg img="url(/assets/img-autenticacion.jpg)" />
            </Grid>
          </Grid>
        </PageBgBlock>

        <Grid container spacing={2} alignItems="stretch">
          <Grid size={8}>
            <PageBgBlock height="100%" pad="1.5rem 3.5rem">
              <Box
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  display: "flex",
                  height: "100%",
                }}
              >

               <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 4, 
        marginBottom: 3
      }}
    >
      <Avatar
        alt="Usuario"
        src="/assets/CpE-logo.png"
        sx={{
           border: "1px solid gray",
        borderRadius: "50%",
          width: "9rem", 
          height: "9rem", 
        }}
      >
        
      </Avatar>
      <Typography variant="h1" sx={{ lineHeight: 1 }}> 
        Conectividad para la Educación 2030
      </Typography>
    </Box>

               
                
                <Box justifyContent="center" sx={{maxWidth: "100%", margin: '0 auto'}}>
                  <Typography variant="body1"  align="left" sx={{ marginBottom: "1.5rem"}}>
                        
Proyecto del Fondo de Desarrollo de las Telecomunicaciones de SUBTEL, tiene por objetivo proveer de Servicios de Conectividad con acceso a Internet a los Establecimientos Educacionales   comunicados y seleccionados previamente por el Ministerio de Educación (MINEDUC), buscando reducir la brecha digital e impulsar el acceso a las tecnologías de información y comunicación.                  </Typography>
                </Box>

            

                <PageButtonSimple onClick={() => window.open("https://www.innovacion.mineduc.cl/", "_blank")}>
                  Ir al sitio del proyecto
                </PageButtonSimple>
              </Box>
            </PageBgBlock>
          </Grid>
          <Grid size={4}>
            <PageBgBlock pad="30px 0 50px" height="100%">

              <Typography variant="h5" align="center" sx={{ fontFamily: "Roboto", fontWeight: "300"}}>¿Qué día es hoy?</Typography>

              <Divider sx={{ margin: "1.5rem 0", borderColor: "#F3F3F3"}}/>
              <Box
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  display: "flex",
                  height: "100%",
                }}
              >

                <Typography variant="h3" align="center" sx={{ fontFamily: "Roboto", marginBottom: "-1rem"}}>{month}</Typography>
                <Typography variant="h1" align="center" sx={{ fontFamily: "Roboto", marginBottom: "-1rem", fontSize: '8.75rem', color: '#0F69C4'}}>{current_date.getDate()}</Typography>
                

                <Typography variant="h3" align="center" sx={{fontFamily: "Roboto"}}>Año {current_date.getFullYear()}</Typography>

              </Box>
            </PageBgBlock>
          </Grid>
        </Grid>
      </Stack>
    </PageBody>
  );
}

import React, { useState, useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";

import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import { NumericFormat } from "react-number-format";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import axios from "../../api/axios";
import Alert from "@mui/material/Alert";
import { Divider } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import InputAddress from "../GeneralComponents/InputAddress";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import ModalStandard from "../GeneralComponents/ModalStandard";
import HourglassLoader from "../GeneralComponents/HourglassLoader";

import { useNavigate } from "react-router-dom";




function validarRut(rut) {
    rut = rut.replace(/\./g, "").replace(/-/g, "").replace(/\s+/g, '').toUpperCase();
    if (!/^\d{7,8}[0-9K]$/.test(rut)) {
        return false;
    }

    const aux = rut.slice(0, -1);
    const dv = rut.slice(-1);

    const reverted = Array.from(aux).reverse().map(Number);

    const factors = [2, 3, 4, 5, 6, 7];
    let sum = 0;

    for (let i = 0; i < reverted.length; i++) {
        sum += reverted[i] * factors[i % factors.length];
    }

    const res = (11 - (sum % 11)) % 11;

    if (res === 11) return dv === "0";
    if (res === 10) return dv === "K";
    return dv === res.toString();
}


function formatearRut(rut) {
    rut = rut.replace(/^0+/, "").replace(/\./g, "").replace(/-/g, "").replace(/\s+/g, '');
    const cuerpo = rut.slice(0, -1);
    const dv = rut.slice(-1);
    if (rut.length > 1){
      return cuerpo + "-" + dv;
    }else{
      return cuerpo+dv
    }
}


export default function EstablecimientoEdit() {
  const { setPageTitle, setDrawerHidding, userSesion } =
    useContext(GeneralContext);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [techTypes, setTechTypes] = useState([]);
  const [RBDList, setRBDList] = useState([""]);
  const [submitConfirm, setSubmitConfirm] = useState(false);
  const [rbdLoading, setRbdLoading] = React.useState(false);
  const fusRef = useRef();
  const rutRef = useRef(null);
  const evidenceRef = useRef(null);
  const [evError, setEvError] = useState(true);
  const [error, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");


  const [errorAddress, setErrorAddress] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);


  const [errors, setErrors] = useState({rut_rep_1: false, rut_rep_2: false});

  const [formData, setFormData] = useState({
  	rbd: "",
    rut_rep_1: "",
    nombre_rep_1: "",
    email_rep_1: "",
    rut_rep_2: "",
    nombre_rep_2: "",
    email_rep_1: "",
    nombre: "",
    id_zona: "",
    fecha_creacion: "",
    email: "",
    latitud: "",
    longitud: "",
    tipo_establecimiento: "",
    telefono: "",
    direccion: "",
    direccion2: "",
    descripcion: "",
    jornada_a: false,
    jornada_b: false,
    jornada_c: false,
    jornada_d: false,
    option: null,

  });

    const handleErrorChange = (error) => {
    setErrorAddress(error);
  };

    const validateForm = () => {
    const newErrors = {};
    if (
      (formData.telefono.trim() &&
            !/^\d{9}$/.test(formData.telefono)) && formData.telefono !== "NULL" && formData.telefono !== ""
    ) {
      newErrors.telefono = "Debe ser un número de teléfono válido de 9 dígitos";
    }

    if (
      formData.direccion.trim() &&
      formData.direccion.trim().length < 5 
    ) {
      newErrors.direccion = "La dirección debe tener al menos 5 caracteres";
    }

  
    /*if (errors.rut_rep_1){
      newErrors.rut_rep_1 = "Rut inválido"
    }
    if (errors.rut_rep_2){
      newErrors.rut_rep_2 = "Rut inválido"
    }*/

      if ( formData.email &&
      ( !formData.email.trim() ||
            !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email))  && formData.email !== "NULL" && formData.email !== ""
    ) {
      newErrors.email = "E-mail inválido";
    }

    /*if ( formData.email_rep_2 &&
      (!formData.email_rep_2.trim() ||
            !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email_rep_2))  && formData.email_rep_2 !== "NULL" && formData.email_rep_2 !== ""
    ) {
      newErrors.email_rep_2 = "E-mail inválido";
    }

    if ( formData.email_rep_1 &&
      (!formData.email_rep_1.trim() ||
            !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email_rep_1))  && formData.email_rep_1 !== "NULL" && formData.email_rep_1 !== ""
    ) {
      newErrors.email_rep_1 = "E-mail inválido";
    }*/
    setErrors(newErrors);
    return newErrors;
  };

    const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };




    const handleRutChange = (e) => {
        const { name, value } = e.target;
        const isValid = validarRut(value);

        setErrors({
      ...errors,
      [name]: !isValid && value !=="",
    });

    setFormData({
      ...formData,
      [name]: formatearRut(value),
    });
    };



function getEes(rbd_temp) {

        axios.getEesInfo(rbd_temp)
            .then((response) => {

                const updatedData = Object.fromEntries(
                    Object.entries(response.data).filter(([key]) => key !== "rbd")
                );

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ...updatedData,
                    rbd: rbd_temp,
                }));

                setError(false);
                setErrorMsg("");
        setLoading(false);
            })
            .catch((error) => {
                console.error("Error en getEes:", error);

                setError(true);
        setLoading(false);
            });
}

function getEes2(rbd_temp) {
        axios.getEesData(rbd_temp, "1")
            .then((response) => {
                const tempRep =
                    response.data.nombres_representantes.length === 1
                        ? [response.data.nombres_representantes[0], "Sin representante"]
                        : response.data.nombres_representantes;

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ees_name: response.data.nombre_colegio,
                    nombre_rep_1: response.data.nombres_representantes.length > 0 ? response.data.nombres_representantes[0] : "",
                    nombre_rep_2: response.data.nombres_representantes.length > 1 ? response.data.nombres_representantes[1] : "",
                    rut_rep_1: response.data.rut_representates.length > 0 ? response.data.rut_representates[0] : "",
                    rut_rep_2: response.data.rut_representates.length > 1 ? response.data.rut_representates[1] : "",
                }));

                setError(false);
            })
            .catch((error) => {
                console.error("Error al obtener datos del EES:", error);

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    nombre_rep_1: "",
                    nombre_rep_2: "",
                    rut_rep_1: "",
                    rut_rep_2: "",
                }));

                setError(true);
            });
}

    




  function handleClick(event) {
    event.preventDefault();
        setLoadingButton(true);



    
    if (Object.keys(validateForm()).length > 0) {
      setLoadingButton(false);
      return;
    }

        if (!submitConfirm) {
      setSubmitConfirm(!submitConfirm);
      setLoadingButton(false);
      return;
    }
      setLoading(true);
      axios
        .putEesInfo(formData.rbd, formData)
        .then((response) => {


              setModalError(false);
              setModalContent("Datos modificados correctamente");
              setOpen(true);
        })
        .catch((error) => {
              setModalError(true);
              setModalError("La modificación de datos del establecimiento falló");
              setLoading(false);})
        .finally(() => {
          setLoading(false);
      		setLoadingButton(false);
      		setSubmitConfirm(false);
        });
 
    
  }
  const handleSwitch = (event) => {
    setFormData({ ...formData, checked: event.target.checked });
  };
  function handleDropDown(value) {
    setFormData({ ...formData, tech_id: value });
  }


  useEffect(() => {
    const fetchData = async () => {
        axios
          .getEesByRut(userSesion.rut)
          .then((response) => {
          	getEes(response.data.rbd);
          	getEes2(response.data.rbd);
          })
          .catch((error) => {
          });
    };
    fetchData();

    setDrawerHidding(false);
  }, []);


    return (
      <PageBody>
        
      <PageBgBlock pad="0">
          {loading ? (
            <HourglassLoader />
          ) : (
            <>
              <Box
                sx={{
                  paddingLeft: "9rem",
                  paddingRight: "9rem",
                  paddingY: "2rem",
                }}
              >
          <Stack sx={{ background: "#FFFFFF", borderRadius: "0.5rem" }}>
            <Box
              ref={fusRef}
            >
              <Stack alignItems="left" justifyContent="center" spacing={2}>
                <Typography
                  variant="h1"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
                >
                  MODIFICAR ESTABLECIMIENTO
                </Typography>

                <Box
                  justifyContent="center"
                  sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                >
                  <Typography
                    variant="body"
                    align="center"
                    sx={{ marginBottom: "2rem", maxWidth: "45rem" }}
                  >
                    Para <b>modificar</b> los datos actuales del establecimiento cambie o complete los campos a editar, cuide que la información esté correcta antes de modificar.
                  </Typography>
                </Box>

                <form onSubmit={handleClick} id="parque_update">

                    
                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <Box >
                <FormControl sx={{ minWidth: "100%" }}>
                        <TextField
                        disabled
                        id="outlined-required"
                        label="RBD"
                        size="small"
                        sx={{ width: "100%" }}
                        value={formData.rbd}
                      />
                    </FormControl>
                    </Box>
                      </Grid>

                    <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          disabled
                          id="outlined-required"
                          label="Nombre"
                          name="nombre"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.nombre}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                      <Grid size={12}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField

                            disabled
                          id="outlined-required"
                          label="Dirección"
                          size="small"
                          name="direccion"
                          sx={{ width: "100%" }}
                          value={formData.direccion}
                      error={!!errors.direccion}
                      helperText={errors.direccion}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>


                      <Grid size={12}>

                      <Grid sx={{ width: "100%", marginY: 2 }} size={12}>
                            <Box >
                              <InputAddress
                                error={errorAddress}
                                errorAddress ={handleErrorChange}
                                option={formData.option}
                                direccion={formData.direccion2}
                                handleAddress={(newValue) =>
                                  setFormData({
                                    ...formData,
                                    ["direccion2"]: newValue,
                                  })
                                }
                                handleOption={(newValue) =>
                                  setFormData({
                                    ...formData,
                                    ["option"]: newValue,
                                  })
                                }


                                margin="0"
                              ></InputAddress>
                            </Box>
                          </Grid>


                      </Grid>

                      <Grid size={12}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField

                            required
                          id="outlined-required"
                          label="Dirección"
                          size="small"
                          name="direccion"
                          sx={{ width: "100%" }}
                          value={formData.direccion2}
                      error={!!errors.direccion}
                      helperText={errors.direccion}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>


                      <Grid size={4}>
                <FormControl fullWidth variant="outlined">
                        <TextField
                        disabled
                      name="latitud"
                      label="Latitud"
                      size="small"
                      value={formData.latitud}
                    />
                  </FormControl>
                      </Grid>
                      <Grid size={4}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                        disabled
                      name="longitud"
                      label="Longitud"
                      size="small"
                      value={formData.longitud}
                    />
                  </FormControl>
                      </Grid>

                      <Grid size={4}>

                  <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                            disabled
                          id="id_zona"
                          label="ID Zona"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.id_zona}
                        />
                        </FormControl>

              </Grid>

                      
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="telefono"
                      label="Teléfono"
                      size="small"
                      value={formData.telefono}
                      onChange={handleInputChange}
                      error={!!errors.telefono}
                      helperText={errors.telefono}
                    />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="email"
                      label="Email de establecimiento"
                      size="small"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </FormControl>
                </Grid>


                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                            disabled
                          name="tipo_establecimiento"
                          label="Tipo de establecimiento"
                          size="small"
                          value={formData.tipo_establecimiento}
                        />
                  </FormControl>

                         
                    </Grid>
                <Grid size={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                        disabled
                      name="fecha_creacion"
                      label="Fecha de ingreso"
                      size="small"
                      value={formData.fecha_creacion}
                    />
                  </FormControl>
                </Grid>

         <Grid size={4} sx={{paddingTop: 1}}>

                  <Typography
                    variant="body" 
                  >
            Tipo de jornada:
            </Typography>
         </Grid>       

        <Grid size={2}>
          <FormControlLabel 
            control={
              <Checkbox checked={formData.jornada_a} disabled name="jornada_a" />
            }
            label="A"
          />

          </Grid>
        <Grid size={2}>
          <FormControlLabel  
            control={
              <Checkbox checked={formData.jornada_b} disabled name="jornada_b" />
            }
            label="B"
          />
          </Grid>
        <Grid size={2}>
          <FormControlLabel
            control={
              <Checkbox checked={formData.jornada_c} disabled name="jornada_c" />
            }
            label="C"
          />
          </Grid>
        <Grid size={2}>
          <FormControlLabel
            control={
              <Checkbox checked={formData.jornada_d} disabled name="jornada_d" />
            }
            label="D"
          />
          </Grid>




                    </Grid>


                    
                    <Grid container spacing={2}>
                    <Grid size={12}><Divider sx={{marginTop: 4, marginBottom: 2  }}></Divider></Grid>
                    	<Grid size={12}>
                    	<Typography 
                    variant="h4" sx={{fontWeight: "400"}}
                  >
            DATOS REPRESENTANTE(S) DE ESTABLECIMIENTO
            </Typography>



                   </Grid>	
                    	<Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          disabled
                                required
                                id="outlined-required"
                                label="RUT Representante EES 1"
                                size="small"
                                name="rut_rep_1"
                                helperText={errors.rut_rep_1 ? "RUT inválido" : "(12345678-9)"}
                                error={!!errors.rut_rep_1} 
                                value={formData.rut_rep_1}
                                sx={{
                                    width: "100%",
                                }}
                                onChange={handleRutChange}
                            />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                                id="outlined-required"
                                label="RUT Representante EES 2"
                                size="small"
                                name="rut_rep_2"
                                helperText={errors.rut_rep_2 ? "RUT inválido" : "(12345678-9)"}
                                error={!!errors.rut_rep_2} 
                                value={formData.rut_rep_2}
                                sx={{
                                    width: "100%",
                                }}
                                onChange={handleRutChange}
                            />
                        </FormControl>
                      </Grid>

                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                        required
                          disabled
                          id="outlined-required"
                          label="Nombre Representante EES 1"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.nombre_rep_1}
                          name="nombre_rep_1"
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          required={formData.rut_rep_2 !=="" || true}
                          id="outlined-required"
                          label="Nombre Representante EES 2"
                          size="small"
                          sx={{ width: "100%" }}
                          name="nombre_rep_2"
                          value={formData.nombre_rep_2}
                          onChange={handleInputChange}
                        />
                        </FormControl>
                      </Grid>

                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          id="outlined-required"
                          label="Email Representante EES 1"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.email_rep_1}
                          name="email_rep_1"
                          onChange={handleInputChange}
	                      error={!!errors.email_rep_1}
	                      helperText={errors.email_rep_1}
                        />
                        </FormControl>
                      </Grid>
                      <Grid size={6}>
                        <FormControl sx={{ width: "100%" }} size="small">
                        <TextField
                          id="outlined-required"
                          label="Email Representante EES 2"
                          size="small"
                          sx={{ width: "100%" }}
                          name="email_rep_2"
                          value={formData.email_rep_2}
                          onChange={handleInputChange}
	                      error={!!errors.email_rep_2}
	                      helperText={errors.email_rep_2}
                        />
                        </FormControl>
                      </Grid>

                      <Grid size={12}><Divider sx={{marginTop: 4, marginBottom: 2  }}></Divider></Grid>

                 
                      <Grid size={12} >
                        <Typography
                        variant="h4" sx={{fontWeight: "400"}}
                        >
                        ¿NECESITA ALGUNA MODIFICACIÓN MÁS?
                        </Typography>

                        </Grid>

                        <Grid size={12}>
                              <FormControl
                                sx={{ width: "100%", maxWidth: "100%" }}
                              >
                                <TextField
                                  multiline
                                  minRows={4}
                                  maxRows={30}
                                  id="outlined-required"
                                  label="Texto aquí"
                                  placeholder="Texto aquí"
                                  size="small"
                                  value={formData.descripcion}
                                  onChange={(event) =>
                                    setFormData({
                                      ...formData,
                                      descripcion:
                                        event.target.value,
                                    })
                                  }
                                />
                              </FormControl>
                            </Grid>


                      {submitConfirm ? (
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      size={12}
                      sx={{ paddingY: "2rem" }}
                    >
                      <Alert
                        icon={false}
                        variant="filled"
                        severity="warning"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          "& .MuiAlert-message": {
                            textAlign: "center",
                            width: "inherit",
                          },
                        }}
                      >
                        <Typography
                          variant="body"
                          color="inherit"
                          sx={{ textAlign: "center" }}
                        >
                          Atención: Revise con atención los datos proporcionados en la modificación para evitar procesos erroneos. Al presionar ‘Modificar establecimiento’ se hará el cambio en nuestras bases de datos, si no está seguro salga de la edición sin modificar.
                        </Typography>
                      </Alert>
                    </Grid>
                  ) : (
                    ""
                  )}

                    </Grid>



          <Box sx={{margin: "2rem auto 0"}}>
            <PageButtonSimple
            disabled={{loadingButton}}
              width="30rem"
                        form="parque_update"
                        startIcon={<ModeEditOutlineOutlinedIcon/>}
                        loading={loading}
                        disabled={ error}
                      >
                        Modificar establecimiento
                      </PageButtonSimple>
            
          </Box>

                </form>
              </Stack>
            </Box>
          </Stack>
            </Box>    

  
          </>
          )}
        </PageBgBlock>    
            <ModalStandard
          open={open}
          error={modalError}
          title={modalContent}
          btnText="Ir a Inicio"
          onClick={() => navigate("/home")}
          onClose={handleClose}
        ></ModalStandard>

      </PageBody>
    );
  
}

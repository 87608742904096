import React, { useState, useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import "dayjs/locale/es";
import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress, Divider, FormControl, TextField } from '@mui/material';
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import ContingenciasHiddenPreview from "./ContingenciasHiddenPreview";
import PageError from "../GeneralComponents/PageError";
import {DAYS} from "../../Constants/Constants";





let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ContingenciasPreview() {
  const { userSesion, setPageTitle, setDrawerHidding } = useContext(GeneralContext);


  //const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [documentVisible, setDocumentVisible] = useState(true);

  const [formData, setFormData] = useState({
    name_emisor: userSesion.nombre,
    rut_emisor: userSesion.rut,
    nombre_colegio: "",
    id_operador: "",
    id_zona: "",
    operator_name: "",
    rbd: "",
    ees_managers: ["", ""],
    ees_managers_email: ["", ""],
    ees_temporal_manager_list: [""],
    ees_temporal_manager: "",
    ees_temporal_manager_email: "",
    checked: false,
    contingencia_programada: false,
    programada_descripcion: "",
    programada_inicio_contingencia: dayjs(),
    programada_fin_contingencia: dayjs(),
    programada_hora_inicio: dayjs().hour(0).minute(0),
    programada_hora_termino: dayjs().hour(23).minute(59),
    especifica_fecha_hora_resolucion: dayjs(),
    especifica_fecha_hora_ocurrencia: dayjs(),
    especifica_codigo_evento: "",
    especifica_origen_falla: "",
    especifica_extension_falla: "",
    especifica_descripcion_falla: "",
    days:  DAYS.reduce((acc, dia) => ({ ...acc, [dia]: false }), {}),
    extension : "rbd",
    zonas: [],
  });


    const links = [
    { name: 'Inicio', path: '/home' },
    { name: 'Listado Contingencias', path: '/contingencias_lista' },
    { name: 'Firma Contingencia', path: '/contingencias_firma/'+id },
  ];


const fetchData = (query) => {
    setLoading(true);
    axios
        .getContingencia(id)
        .then((response) => {
            const datos = response.data.datos || {};
            const estado = response.data.estado || "";
            const parseTime = (timeString) => {
                if (!timeString) return "";
                const [hours, minutes, seconds] = timeString.split(":").map(Number);
                return dayjs().set("hour", hours).set("minute", minutes).set("second", seconds);
            };

            setFormData({
                ...datos,
                especifica_fecha_hora_ocurrencia: dayjs(datos["especifica_fecha_hora_ocurrencia"] || null),
                especifica_fecha_hora_resolucion: dayjs(datos["especifica_fecha_hora_resolucion"] || null),
                programada_inicio_contingencia: dayjs(datos["programada_inicio_contingencia"] || null),
                programada_fin_contingencia: dayjs(datos["programada_fin_contingencia"] || null),
                estado,
                extension: datos["extension"] || "rbd",
                programada_hora_inicio: parseTime(datos["programada_hora_inicio"]),
                programada_hora_termino: parseTime(datos["programada_hora_termino"]),
            });
        })
        .catch((error) => {
            console.error("Error al obtener los datos:", error);
            setDocumentVisible(false);
        })
        .finally(() => {
            setLoading(false);
        });
};

  useEffect(() => {
    fetchData("");
  }, []);

      useEffect(() => {
        fetchData()
        setDrawerHidding(false)
    }, [setPageTitle, setDrawerHidding]);

  return (
    <PageBody>
          <BreadcrumbStandard links={links} />
      <PageBgBlock pad="0" >
      {loading ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
            :
             documentVisible ? 
             (

      <PageBgBlock pad="2rem 9rem" >
      <>
             <ContingenciasHiddenPreview formData={formData} id={id}></ContingenciasHiddenPreview>
             <Divider sx={{ my: 2 }} />

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      JUSTIFICACIÓN DE SUBTEL
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <TextField
                        disabled
                        multiline
                        minRows={4}
                        maxRows={30}
                        id="outlined-required"
                        placeholder="Texto aquí"
                        size="small"
                        value={formData.justificacion_subtel || ""}
                      />
                    </FormControl>
             </>
             </PageBgBlock>) : (
            <PageError>
                  El documento al que intenta acceder no esta disponible.
                </PageError>
          )
        }
      </PageBgBlock>

    </PageBody>
  );
}

import axios from 'axios';
const BASE_URL = process.env.REACT_APP_ROOT_URL;
//const BASE_URL =  '/api';


 
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
    headers: {
      "Cache-Control": "no-cache",'Content-Type': 'application/json'
    },
    withCredentials: true,
    showSnackbarOnSuccess: null,
    showSnackbarOnError: true,
});


export default {
    getUserLogin: (jwt) =>
    axiosPrivate({
        'method':'GET',
        'url':'/auth/user', 
        headers: {
          'Authorization': 'Bearer '+ jwt,
        }, 
        
    }),
    getUser: () =>
    axiosPrivate({
        'method':'GET',
        'url':'/auth/user', 

    }),
    verify: () =>
    axiosPrivate({
        'method':'GET',
        'url':'/auth/verify'
    }),
    
    checkSignature: (data) =>
    axiosPrivate({
        'method':'POST',
        'url': '/service/esdb/verify_signature',
        'data': data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },

    }),
    sendFilesFas: (data) =>
    axiosPrivate({
        'method':'POST',
        'url': '/service/esdb/create/fas',
        'data': data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        'showSnackbarOnSuccess': "Fas enviado y firmado",

    }),
    sendFilesFus: (data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/create/fus',
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Fus enviado y firmado",
  
      }),
    sendFilesContingencias: (data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/create/contingencias',
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Contingencia enviada y firmada",
      }),
    overwriteFilesContingencias: (data, id) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/overwrite/contingencias/'+id,
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Contingencia enviada y firmada",
      }),
    sendFilesApelacion: (data, type) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/create/'+type,
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Apelación enviada y firmada",
      }),
      getZonas: () =>
      axiosPrivate({
          'method':'GET',
          'url':'/service/cedb/zona_autocomplete/',
          showSnackbarOnError: false,
      }),
      getAllZonas: () =>
      axiosPrivate({
          'method':'GET',
          'url':'/service/cedb/zonas/',
          showSnackbarOnError: false,
      }),

      putOficiosRBD: (data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/cedb/oficios_rbd/',
          data: data,
          showSnackbarOnError: false,
        'showSnackbarOnSuccess': "Operación exitosa",
      }),

      getZonasOperador: (id) =>
      axiosPrivate({
          'method':'GET',
          'url':'/service/cedb/zonas_por_operador/'+id,
          showSnackbarOnError: false,
      }),
      downloadDocuments: (docType, docsId) =>
        axiosPrivate({
            method: 'POST',
            url: `/service/esdb/download_documents/${docType}`,
            data: docsId,
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        }),
    getContingenciasData: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/get_contingencias_by_rut/'+rut, 
        showSnackbarOnError: false,
    }),
    getContingencia: (id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/get_contingencias_by_id/'+id, 
        showSnackbarOnError: false,
    }),
    getFasFusInfo: (rbd) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/velocidades_fas/'+rbd+'/', 
        showSnackbarOnError: false,
    }),
    getEesData: (rbd, jwt) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/info_ees_fas/'+rbd+'/'+jwt+'', 
        showSnackbarOnError: false,
    }),
      rejectDoc: (doc_type, id, data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/reject/'+doc_type+'/'+id,
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Documento rechazado correctamente",
  
      }),
      signDoc: (doc_type, id, data) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/sign_document/'+doc_type+'/'+id,
          'data': data,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          'showSnackbarOnSuccess': "Documento firmado correctamente",
  
      }),
      getEesByRut: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/info_ees_por_rrpp_ees/'+rut, 
        showSnackbarOnError: false,
    }),
    getEes: (rbd) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/ees/'+rbd, 
        showSnackbarOnError: false,
    }),
    getEesParque: (rbd) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/buscar_estado_parque/'+rbd+"/1", 
        showSnackbarOnError: false,
    }),
    putBajaByRBD: (rbd, data) =>
      axiosPrivate({
        'method':'PUT',
        'url':'/service/cedb/dar_de_baja_ees/'+rbd+"?jwt_token=1", 
        data: data, 
        showSnackbarOnError: false,
        'showSnackbarOnSuccess': "Operación exitosa",
    }),
    updateTechByZone: (rbd, tech_id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/update_tecnologia_by_zona/'+rbd+"/"+tech_id, 
        'showSnackbarOnSuccess': "Operación exitosa",
        showSnackbarOnError: false,
    }),
    updateTechByRBD: (rbd, tech_id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/update_tecnologia_by_zona/'+rbd+"/"+tech_id, 
        showSnackbarOnError: false,
        'showSnackbarOnSuccess': "Operación exitosa",
    }),
    getParque: () =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/endpoint_parque2/', 
        showSnackbarOnError: false,
    }),

      
    putEesInfo: (rbd, data) =>
      axiosPrivate({
        'method':'PUT',
        'url':'/service/cedb/modificar_info_ees/'+rbd, 
        'data': data,
        showSnackbarOnError: false,
        'showSnackbarOnSuccess': "Operación exitosa",
    }), 
    createTicket: (data) =>
      axiosPrivate({
        'method':'POST',
        'url':'/service/esdb/create_ticket', 
        'data': data,
        showSnackbarOnError: false,
        'showSnackbarOnSuccess': "Ticket de soporte creado con éxito",
    }),
    getUUID: (uuid) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/ardb/'+uuid, 
    }),
    getUserRBD: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/usuarios_ees/'+rut, 
    }),
    getRBD: (rbd) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/rbd_autocomplete/'+rbd, 
    }),
    getRBDOperador: (rbd, operador) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/rbd_autocomplete_operador/'+rbd+'/'+operador, 
    }),
    getEesInfo: (id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/ees/'+id, 
    }),
    getFas: (id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/fas/'+id, 
        showSnackbarOnError: false,
    }),
    getFus: (id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/fus/'+id, 
        showSnackbarOnError: false,

    }),
    getFasList: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/fas_endpoint/'+rut+'/1', 
    }),
    getFusList: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/fus_endpoint/'+rut+'/1', 
    }),
    getOperator: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/operador_por_operador_instalador/'+rut, 
    }),
    getTechTypes: () =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/tipos_tecnologia/', 
    }),
    getPrefacturas: (periodo) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/prefacturas/completas/'+periodo, 
    }),
    getDevengos: (periodo) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/devengos/completas/'+periodo, 
        showSnackbarOnError: false,
    }),
    getPrefacturasCompletas: () =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/prefacturas/completas_periodos/', 
    }),
    getPrefacturasCompletasHistorial: () =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/prefacturas/completas_periodos_historial/', 
    }),
    getPrefacturasRBD: (periodo) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/prefactura_rbd/', 
    }),
    getPrefacturaByOperador: (id, periodo) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/endpoint_operador_administrador_por_operador/'+id+'/'+periodo, 
        showSnackbarOnError: false,
    }),
      acceptPrefactura: (id) =>
      axiosPrivate({
          'method':'POST',
          'url':'/service/esdb/accept_prefactura/'+id,

      }),
    getPrefactura: (query) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/endpoint_operador_administrador/'+query, 
    }),
    getApelaciones: (rut) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/apelaciones_endpoint_firmas/'+rut, 
    }),
    getApelacion: (query) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/apelaciones_endpoint/'+query, 
    }),

    getReportesComerciales: (query) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/reportes_comerciales/'+query, 
    }),
    putConfigUser: (rut, data) =>
      axiosPrivate({
        'method':'PUT',
        'url':'/service/cedb/configuracion_usuario/'+rut, 
        'data': data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        'showSnackbarOnSuccess': "Operación exitosa",
    }),
    putConfigUserSign: (rut, data) =>
      axiosPrivate({
        'method':'PUT',
        'url':'/service/cedb/configuracion_usuario/'+rut+'/firma',
        'data': data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
    }),
    getCode: (id) =>
      axiosPrivate({
        'method':'GET',
        'url':'/service/cedb/endpoint_generar_uuid/'+id, 
        showSnackbarOnError: false,
    }),


}

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Radio, RadioGroup } from "@mui/material";

import { NumericFormat } from "react-number-format";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import InputRut from "../GeneralComponents/InputRut";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import ImageGallery from "../GeneralComponents/ImageGallery";

import SignatureFooter from "../GeneralComponents/SignatureFooter";
import { QUESTIONS } from "../../Constants/Constants";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FusHiddenPreview({ formData, techTypes, children = null, id = null }) {
  const [showQuestions] = useState(true);
  const [answers] = useState(Array(QUESTIONS.length).fill("yes"));


  const [errors, setErrors] = useState({});

    const validateField = (field, value) => {
    let error = "";

    

    switch (field) {
      case "int_speed_down":
        if (formData[field] < formData.validation_data[field]) error = `La velocidad de descarga internacional es inferior a la pactada (${formData.validation_data[field]}Mbps).`;
        break;

      case "speed_up":
        if (formData[field] < formData.validation_data[field]) error = `La velocidad de subida es inferior a la pactada (${formData.validation_data[field]}Mbps).`;
        break;

      case "speed_down":
        console.log(value)
        console.log(formData.validation_data[field])
        if (formData[field] < formData.validation_data[field]) error = `La velocidad de descarga es inferior a la pactada (${formData.validation_data[field]}Mbps).`;
        break;

      case "latency":
        if (formData[field] > formData.validation_data[field]) error = `La latencia es superior a la pactada (${formData.validation_data[field]}ms).`;
        break;

      case "packet_loss":
        if (formData[field] > formData.validation_data[field]) error = `La pérdida supera la pactada (${formData.validation_data[field]}%).`;
        break;

      default:
        break;
    }

    return error;
  };

  const validateAllFields = (data) => {
    console.log(data);
    const newErrors = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      const error = validateField(key, value);
      if (error) {
        newErrors[key] = error;
      }
    });
    return newErrors;
  };

  useEffect(() => {
    if(formData && formData.validation_data){
    const initialErrors = validateAllFields(formData.validation_data);
    console.log(initialErrors);
    setErrors(initialErrors);

    }
  }, [formData]);

  if (formData.ees_managers && techTypes && techTypes.length > 0) {
    return (
      <>
          <PageBgBlock pad="2rem 9rem">
            <Stack alignItems="left" justifyContent="center" spacing={2}>
              <Typography
                variant="h1"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
              >
                FORMULARIO FUS
              </Typography>

                  <Box
                    justifyContent="center"
                    sx={{ display: "flex", margin: "0.2rem!important" }}
                  >
                    <Typography
                      variant="small"
                      color="inherit"
                      sx={{
                        textAlign: "center",
                        justifyContent: "center",
                        paddingY: "0",
                        marginTop: "0",
                      }}
                    >
                      N° folio:
                      <Typography
                        variant="small"
                        sx={{ paddingLeft: "1rem", marginTop: "0", color: id ? '' : '#FFF' }}
                      >
                        {String(id || "").padStart(8, "0")}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    justifyContent="center"
                    sx={{ display: "flex", margin: "0.2rem!important" }}
                  >
                    <Typography
                      variant="small"
                      color="inherit"
                      sx={{
                        textAlign: "center",
                        justifyContent: "center",
                        paddingY: "0",
                      }}
                    >
                      Vinculado a FAS N° folio:{" "}
                      {String(formData.fas_id || "").padStart(8, "0")}
                    </Typography>
                  </Box>

              <Box
                justifyContent="center"
                sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
              >
                <Typography
                  variant="body"
                  align="center"
                  sx={{ marginBottom: "2rem" }}
                >
                  Le presentamos el Formulario de Upgrade del Servicio, por
                  favor llene todos los campos indicados para un proceso exitoso
                  entre todos los involucrados (operador, EES, SUBTEL y MINEDUC)
                </Typography>
              </Box>
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                DATOS DEL OPERADOR
              </Typography>
              <form>
                <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                  <Grid container spacing={2}>
                    <Grid size={6}>
                      <TextField
                        disabled
                        id="outlined-required"
                        label="RUT Operador Instalador"
                        size="small"
                        sx={{ width: "100%" }}
                        value={formData.rut}
                      />
                    </Grid>
                    <Grid size={6}>
                      <TextField
                        disabled
                        id="outlined-required"
                        label="Operador"
                        size="small"
                        sx={{ width: "100%" }}
                        value={formData.operator_name}
                      />
                    </Grid>
                  </Grid>
                </FormControl>

                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  DATOS DEL EES
                </Typography>

                <Box>
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid size={12}>
                        <TextField
                          disabled
                          id="outlined-required"
                          label="RBD"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.rbd}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Box>
                <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                  <Grid container spacing={2}>
                    <Grid size={12}>
                      <TextField
                        disabled
                        id="outlined"
                        label="Nombre del Colegio"
                        size="small"
                        value={formData.ees_name}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid size={6}>
                      <TextField
                        label="Representante del establecimiento 1"
                        size="small"
                        disabled
                        value={formData.ees_managers[0]}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid size={6}>
                      <TextField
                        label="Representante del establecimiento 2"
                        size="small"
                        disabled
                        value={formData.ees_managers[1]}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                            <Grid size={6}>

                                <TextField

                                    label="Email Representante del establecimiento 1"
                                    size="small"
                                    disabled
                                    value={formData.ees_managers_email ? formData.ees_managers_email[0] : ""}
                                    sx={{ width: "100%" }}
                                />

                            </Grid>
                            <Grid size={6}>

                                <TextField

                                    label="Email Representante del establecimiento 2"
                                    size="small"
                                    disabled
                                    value={formData.ees_managers_email ? formData.ees_managers_email[1] : ""}
                                    sx={{ width: "100%" }}
                                />

                            </Grid>
                  </Grid>
                </FormControl>
                <Box>
                  <InputRut
                    errorRut={() => {}}
                    handleRut={() => {}}
                    handleEmail={() => {}}
                    checked={formData.checked}
                    email={formData.ees_temporal_manager_email}
                    rut={formData.ees_temporal_manager}
                  ></InputRut>
                </Box>

                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  DATOS DE CONEXIÓN
                </Typography>
                <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                  <Grid container spacing={2}>
                    <Grid size={12}>
                      <InputLabel size="small" id="tech_id">
                        Tecnología
                      </InputLabel>
                      <Select
                        required
                        disabled
                        value={"1"}
                        labelId="tech_id"
                        defaultValue={0}
                        label="Tecnología"
                        size="small"
                        sx={{ width: "100%" }}
                      >
                        {techTypes.map((tech, index) => (
                          <MenuItem
                            key={index + "menu"}
                            value={tech.id_tipo_tecnologia}
                          >
                            {tech.nombre_enlace}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid size={6}>
                      <NumericFormat
                        required
                        disabled
                        customInput={TextField}
                        label="Vel. subida total Nacional (Mbps)"
                        value={formData.speed_up}
                        allowNegative={false}
                        size="small"
                        sx={{ width: "100%" }}
                        error={!!errors.speed_up} 
                        helperText={errors.speed_up}
                      />
                    </Grid>
                    <Grid size={6}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"es"}
                        localeText={
                          esES.components.MuiLocalizationProvider.defaultProps
                            .localeText
                        }
                      >
                        <DateTimePicker
                          disabled
                          label="Fecha instalación"
                          slotProps={{ textField: { size: "small" } }}
                          value={formData.installation_date}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                  <Grid container spacing={2}>
                    <Grid size={6}>
                      <NumericFormat
                        required
                        disabled
                        customInput={TextField}
                        label="Vel. bajada total Nacional (Mbps)"
                        value={formData.speed_down}
                        allowNegative={false}
                        size="small"
                        sx={{ width: "100%" }}
                        error={!!errors.speed_down} 
                        helperText={errors.speed_down}
                      />
                    </Grid>
                    <Grid size={6}>
                      <NumericFormat
                        required
                        disabled
                        customInput={TextField}
                        label="Vel. bajada total Internacional (Mbps)"
                        value={formData.int_speed_down}
                        size="small"
                        sx={{ width: "100%" }}
                        error={!!errors.int_speed_down} 
                        helperText={errors.int_speed_down}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                  <Grid container spacing={2}>
                    <Grid size={6}>
                      <NumericFormat
                        disabled
                        required
                        customInput={TextField}
                        label="Latencia (ms)"
                        value={formData.latency}
                        allowNegative={false}
                        size="small"
                        sx={{ width: "100%" }}
                        error={!!errors.latency} 
                        helperText={errors.latency}
                      />
                    </Grid>
                    <Grid size={6}>
                      <NumericFormat
                        required
                        disabled
                        customInput={TextField}
                        label="Pérdida de paquetes (%)"
                        value={formData.packet_loss}
                        size="small"
                        sx={{ width: "100%" }}
                        error={!!errors.packet_loss} 
                        helperText={errors.packet_loss}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                  <Paper
                    elevation={1}
                    sx={{ width: "100%", borderRadius: "0.5rem" }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        display="flex"
                        justifyContent="flex-start"
                        size={12}
                        sx={{ maxHeight: "small" }}
                      >
                        <Typography variant="body" sx={{ padding: "0.5rem" }}>
                          <Grid container spacing={2}>
                            <Grid
                              display="flex"
                              justifyContent="flex-start"
                              size={12}
                              sx={{ maxHeight: "small" }}
                            >
                              <CollectionsOutlinedIcon
                                sx={{ marginRight: "0.5rem" }}
                              />{" "}
                              {"Subir imágenes de evidencia."}
                            </Grid>
                          </Grid>
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Divider orientation="vertical" flexItem />
                        <LoadingButton
                          disabled
                          component="label"
                          role={undefined}
                          variant="text"
                          tabIndex={-1}
                          startIcon={<FileUploadOutlinedIcon />}
                          sx={{ textDecoration: "underline", marginLeft: "1%" }}
                        >
                          Subir imágenes
                          <VisuallyHiddenInput
                            type="file"
                            accept="image/*"
                            multiple
                          />
                        </LoadingButton>
                      </Grid>
                    </Grid>
                    {formData.images ? (
                    <Grid  container spacing={2}>

              
                                <Grid display="flex" justifyContent="flex-start" alignItems="center" size={12}>

                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

                                        <ListItem sx={{ justifyContent: "flex-start" }}>
                                            <Typography
                                                align="center"
                                            >
                                                Lista de imágenes a subir
                                            </Typography>
                                        </ListItem>
                                        {Array.from(formData.images).map(image => {
                                            return (
                                                <ListItem divider key={image.name} display="flex" alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar variant="rounded" alt="preview" src={URL.createObjectURL(image)} />
                                                    </ListItemAvatar>
                                                    <ListItemText primaryTypographyProps={{ style: { color: "black" } }} primary={image.name} />
                                                </ListItem>
                                            )
                                        }
                                        )
                                        }
                                    </List>

                                </Grid>
                            </Grid>) : (<ImageGallery images={formData.evidence}></ImageGallery>)}
                  </Paper>
                </FormControl>
              </form>
            </Stack>
          </PageBgBlock>
      {!formData.estado ? (

          <Stack justifyContent="center" spacing={2}>
            <Box>
              <List
                sx={{
                  backgroundColor: "#BBDEFB",
                  padding: "1rem 0",
                  borderRadius: "20px",
                  marginTop: "1rem",
                }}
              >
                <ListItem key="header" sx={{ justifyContent: "center" }}>
                  <Typography variant="h1" align="center">
                    ENCUESTA DE SATISFACCIÓN EES
                  </Typography>
                </ListItem>
                <Divider sx={{ border: "1px solid white" }} />
                {QUESTIONS.map((question, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      sx={{ paddingLeft: "9rem", paddingRight: "9rem" }}
                    >
                      <Grid
                        container
                        spacing={2}
                        sx={{ dispaly: "flex", width: "100%" }}
                      >
                        <Grid size={9}>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {question}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid
                          size={3}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <RadioGroup
                            disabled={!showQuestions}
                            row
                            value={answers[index]}
                          >
                            <FormControlLabel
                              disabled={!showQuestions}
                              value="yes"
                              control={<Radio />}
                              label="Sí"
                            />
                            <FormControlLabel
                              disabled={!showQuestions}
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {index < QUESTIONS.length - 1 && (
                      <Divider sx={{ border: "1px solid white" }} />
                    )}{" "}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            
            {!id ? <SignatureFooter mineduc={formData.checked}></SignatureFooter> : null}
          </Stack>
          ) : null}

          {children}
      </>
    );
  }
}

import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import "dayjs/locale/es";

import Alert from '@mui/material/Alert';
import { esES } from "@mui/x-date-pickers/locales";
import axios from "../../api/axios";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";

import ModalStandard from "../GeneralComponents/ModalStandard";
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import PageError from "../GeneralComponents/PageError";
import { Divider} from '@mui/material';
import ContingenciasHiddenPreview from "./ContingenciasHiddenPreview";
import HourglassLoader from "../GeneralComponents/HourglassLoader";

import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_MINEDUC_ADMIN, DAYS} from "../../Constants/Constants";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ContingenciasFirma() {
  const { pageTitle, setPageTitle, setDrawerHidding, userSesion, includeRol } =  useContext(GeneralContext);
  
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [documentVisible, setDocumentVisible] = useState(true);

    const [submitConfirm, setSubmitConfirm] = useState(false);
  const [canSign, setCanSign] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const { id } = useParams();
  const links = [
    { name: 'Inicio', path: '/home' },
    { name: 'Listado Contingencias', path: '/contingencias_lista' },
    { name: 'Firma Contingencia', path: '/contingencias_firma/'+id },
  ];


  const [formData, setFormData] = useState({
    name_emisor: userSesion.nombre,
    rut_emisor: userSesion.rut,
    nombre_colegio: "",
    id_operador: "",
    id_zona: "",
    operator_name: "",
    rbd: "",
    ees_managers: ["", ""],
    ees_managers_email: ["", ""],
    ees_temporal_manager_list: [""],
    ees_temporal_manager: "",
    ees_temporal_manager_email: "",
    checked: false,
    contingencia_programada: false,
    programada_descripcion: "",
    programada_inicio_contingencia: dayjs(),
    programada_fin_contingencia: dayjs(),
    programada_hora_inicio: dayjs().hour(0).minute(0),
    programada_hora_termino: dayjs().hour(23).minute(59),
    especifica_fecha_hora_resolucion: dayjs(),
    especifica_fecha_hora_ocurrencia: dayjs(),
    especifica_codigo_evento: "",
    especifica_origen_falla: "",
    especifica_extension_falla: "",
    especifica_descripcion_falla: "",
    days:  DAYS.reduce((acc, dia) => ({ ...acc, [dia]: false }), {}),
    extension : "rbd",
    zonas: {},
  });


const fetchData = (query) => {
    setLoading(true);

    axios
        .getContingencia(id)
        .then((response) => {
            const datos = response.data.datos || {};
            const estado = response.data.estado || "";
            const parseTime = (timeString) => {
                if (!timeString) return "";
                const [hours, minutes, seconds] = timeString.split(":").map(Number);
                return dayjs().set("hour", hours).set("minute", minutes).set("second", seconds);
            };

            setFormData({
                ...datos,
                especifica_fecha_hora_ocurrencia: dayjs(datos["especifica_fecha_hora_ocurrencia"] || null),
                especifica_fecha_hora_resolucion: dayjs(datos["especifica_fecha_hora_resolucion"] || null),
                programada_inicio_contingencia: dayjs(datos["programada_inicio_contingencia"] || null),
                programada_fin_contingencia: dayjs(datos["programada_fin_contingencia"] || null),
                estado,
                extension: datos["extension"] || "rbd",
                programada_hora_inicio: parseTime(datos["programada_hora_inicio"]),
                programada_hora_termino: parseTime(datos["programada_hora_termino"]),
                ees_managers_email: datos["ees_managers_email"] ? datos["ees_managers_email"] : ["", ""],
            });
        })
        .catch((error) => {
            console.error("Error al obtener los datos:", error);
            setDocumentVisible(false);
        })
        .finally(() => {
            setLoading(false);
        });
};


  function singDoc() {
    //event.preventDefault();
        if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm)
        return;
    }
    setLoadingButton(true);

    const form = new FormData();
    const localFormData = {
        ...formData,
        especifica_fecha_hora_ocurrencia: formData["especifica_fecha_hora_ocurrencia"].locale('es').format('YYYY/MM/DD HH:mm:ss').toString(),
        especifica_fecha_hora_resolucion: formData["especifica_fecha_hora_resolucion"].locale('es').format('YYYY/MM/DD HH:mm:ss').toString(),
        programada_inicio_contingencia: formData["programada_inicio_contingencia"].locale('es').format('YYYY/MM/DD').toString(),
        programada_fin_contingencia: formData["programada_fin_contingencia"].locale('es').format('YYYY/MM/DD').toString(),
        programada_hora_inicio: formData["programada_hora_inicio"].locale('es').format('HH:mm:ss').toString(),
        programada_hora_termino: formData["programada_hora_termino"].locale('es').format('HH:mm:ss').toString(),

    }



    form.append("data", JSON.stringify(localFormData));
        axios
      .signDoc("contingencias",id, form)
      .then((response) => {
          setModalError(false);
          setModalContent("Documento enviado y firmado");
          setOpen(true);
      })
      .catch((error) => {
          setModalError(true);
          setModalError("La firma del documento falló");
      })
      .finally(() => {
       setLoadingButton(false);
        setSubmitConfirm(false);
      });

  }

    function rejectDoc() {
    //event.preventDefault();
    if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm)
        return;
    }
    setLoadingButton(true);
    const form = new FormData();
    const localFormData = {
        ...formData,
        especifica_fecha_hora_ocurrencia: formData["especifica_fecha_hora_ocurrencia"].locale('es').format('YYYY/MM/DD HH:mm:ss').toString(),
        especifica_fecha_hora_resolucion: formData["especifica_fecha_hora_resolucion"].locale('es').format('YYYY/MM/DD HH:mm:ss').toString(),
        fecha_programada_hora_inicio: formData["fecha_programada_hora_inicio"].locale('es').format('YYYY/MM/DD HH:mm:ss').toString(),
        fecha_programada_hora_termino: formData["fecha_programada_hora_termino"].locale('es').format('YYYY/MM/DD HH:mm:ss').toString(),
    }

    form.append("data", JSON.stringify(localFormData));
    axios
      .rejectDoc("contingencias",id, form)
      .then((response) => {
        navigate("/contingencias_lista");
      })
      .catch((error) => {})
      .finally(() => {
       setLoadingButton(false);
        setSubmitConfirm(false);
      });
  }



  useEffect(() => {
    setPageTitle("Firma de Contingencia");
    fetchData("");
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);


   useEffect(() => {   
    if (includeRol([ROL_REP_EES,ROL_REP_EES_TEMP]) && (formData.estado === NO_EES || formData.estado === NO_EES_TEMP)  ){
      setCanSign(true);
    }else if (includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) && formData.estado === NO_SUBTEL){
      setCanSign(true);
    }else if(includeRol([ROL_MINEDUC_ADMIN]) && formData.estado === NO_MINEDUC){
      setCanSign(true);
    }
  }, [userSesion, formData]);

  if (pageLoaded) {
    return (
        <>
          <PageBody>
          <BreadcrumbStandard links={links} />
        {loading ? (
        <PageBgBlock pad="2rem 9rem">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
        </PageBgBlock>

        ) :  loadingButton ? (<HourglassLoader/> ) :
      (documentVisible && canSign ? 


      <>
      <PageBgBlock pad="0" >
      <Box sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}> 
       <ContingenciasHiddenPreview formData={formData} id={id}>
            <>
              {includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) &&
                formData.estado === NO_SUBTEL ? (
                <>

              <Divider sx={{ my: 2 }} />

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      JUSTIFICACIÓN DE SUBTEL
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <TextField
                        multiline
                        minRows={4}
                        maxRows={30}
                        id="outlined-required"
                        placeholder="Texto aquí"
                        size="small"
                        value={formData.justificacion_subtel}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            justificacion_subtel: event.target.value,
                          })
                        }
                      />
                    </FormControl>
                    </>
                ) : null}


        
          <Stack sx={{paddingBottom: "3rem" }}>
                    <Grid container spacing={2} sx={{paddingTop: '2rem'}}>

             {submitConfirm ?
                <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{paddingBottom: "3rem"}}>
                    <Alert icon={false} variant="filled" severity="warning" sx={{ display: "flex", justifyContent: "center", '& .MuiAlert-message': { textAlign: "center", width: "inherit" } }}>
                        <Typography
                            variant="body"
                            color="inherit"
                            sx={{ textAlign: "center" }}
                        >
                            Revise que todos los campos contienen la información correcta antes de firmar la Contingencia. Al hacer clic en 'Firmar y enviar', el documento quedará firmado sin opción de modificación.
                        </Typography>
                    </Alert>
                </Grid>
                :
                ""
            }       
            <Grid size={2}></Grid>
            <Grid size={4}>
              <PageButtonWhite onClick={rejectDoc} loading={loadingButton} width="12rem"> Rechazar</PageButtonWhite>
            </Grid>
            <Grid size={4}>
              <PageButtonBlack onClick={singDoc} loading={loadingButton} justifyContent="center" width="12rem">
                Firmar y enviar
              </PageButtonBlack>
            </Grid>
            <Grid size={2}></Grid>
          </Grid>
          </Stack>

          </>
          </ContingenciasHiddenPreview>

          </Box>
      </PageBgBlock>
      </>
       :

        <PageError>
                El documento al que intenta acceder no esta disponible para ser firmado.
              </PageError>
      
    )}
    <ModalStandard open={open} error={modalError} title={modalContent} btnText="Volver a listado" onClick={() => navigate("/contingencias_lista")} onClose={handleClose}></ModalStandard>
      
    </PageBody>
              </>
    );
  }
}

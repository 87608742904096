import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Typography,
  Chip,
  TextField,
  FormControl,
  InputLabel
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";

import axios from "../../api/axios";
import GeneralContext from "../GeneralContext/GeneralContext";
import PageBody from "../GeneralComponents/PageBody";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

export default function OPReport() {
  const [rows, setRows] = useState([]);
  const { userSesion } = useContext(GeneralContext);
  const [zona, setZona] = useState([]);
  const [rbd, setRbd] = useState([]);
  const [operador, setOperador] = useState("");
  const [timeRange, setTimeRange] = useState("now-24h"); // Default: Últimas 24 horas

  const baseUrl = "/service/grafana/d-solo/be0kjiscwhzwga/reporte-supervision";

  const params = new URLSearchParams({
    "var-Operador": operador,
    "from": timeRange,
    "to": "now",
  });

  zona.forEach((z) => params.append("var-Zona", z));
  rbd.forEach((id) => params.append("var-RBD", id));

  const iframeSrc = `${baseUrl}?orgId=1&${params.toString()}`;

  const getOperator = async () => {
    try {
      const response = await axios.getOperator(userSesion.id);
      setOperador(response.data.operador);
      fetchData(response.data.id_operador);
    } catch (error) {
      console.error("Error en getOperator:", error);
    }
  };

  const fetchData = async (id) => {
    try {
      const response = await axios.getZonasOperador(id);
      const newZonas = response.data.zonas.map((zona) => ({
        ...zona,
        nombre_zona: `ZONA${String(zona.id_zona).padStart(2, '0')}`,
      }));
      setRows(newZonas);
    } catch (error) {
      console.error("Error en fetchData:", error);
    }
  };

  const handleZonaChange = (event) => {
    const selectedZonas = event.target.value;
    setZona(selectedZonas);

    if (selectedZonas.length === 0) {
      setRbd([]);
      return;
    }

    const selectedRBDs = rows
      .filter((row) => selectedZonas.includes(row.nombre_zona))
      .flatMap((row) => row.rbd_list);

    setRbd((prevRbd) => prevRbd.filter((id) => selectedRBDs.includes(id)));
  };

  const handleRbdChange = (event, value) => {
    setRbd(value);
  };

  const getAllRBDs = () => {
    return rows.flatMap((row) => row.rbd_list);
  };

  useEffect(() => {
    getOperator();
  }, []);

  return (
    <PageBody>
      <Box width="100%">
        <Grid
          container
          spacing={1}
          sx={{ marginBottom: "28px", marginTop: "-2rem" }}
        >
        <Grid size={3}>



            <FormControl sx={{ width: "100%" }} size="small">
              <InputLabel>Rango de tiempo</InputLabel>
              <Select
                  id="demo-simple-select"
                    label="Rango de tiempo"
                value={timeRange}
                onChange={(event) => setTimeRange(event.target.value)}
              >
                <MenuItem value="now-12h">Últimas 12 horas</MenuItem>
                <MenuItem value="now-24h">Últimas 24 horas</MenuItem>
                <MenuItem value="now-48h">Últimas 48 horas</MenuItem>
                <MenuItem value="now-7d">Última semana</MenuItem>
                <MenuItem value="now-1M">Último mes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid size={3}>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                multiple
                displayEmpty
                value={zona}
                onChange={handleZonaChange}
                renderValue={(selected) =>
                  selected.length === 0
                    ? "Seleccionar Zona"
                    : selected.join(", ")
                }
              >
                {rows.map((row) => (
                  <MenuItem key={row.nombre_zona} value={row.nombre_zona}>
                    <Checkbox checked={zona.indexOf(row.nombre_zona) > -1} />
                    <ListItemText
                      primary={
                        <Typography sx={{ color: "black!important" }}>
                          {row.nombre_zona}
                        </Typography>
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          <Grid size={3}>
            <Autocomplete
              multiple
              value={rbd}
              onChange={handleRbdChange}
              options={
                zona.length === 0
                  ? getAllRBDs()
                  : rows
                      .filter((row) => zona.includes(row.nombre_zona))
                      .flatMap((row) => row.rbd_list)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder="Seleccionar RBD"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ZonDelete, ...restProps } = getTagProps({
                    index,
                  });
                  return (
                    <Chip
                      key={option}
                      size="small"
                      label={option}
                      onDelete={() => {
                        setRbd(value.filter((chip) => chip !== option));
                      }}
                      deleteIcon={<RemoveCircleOutlineOutlinedIcon />}
                      sx={{
                        padding: 0,
                        backgroundColor: "rgba(61, 108, 174, 0.20)",
                        "& .MuiChip-deleteIcon": {
                          color: "rgba(61, 108, 174, 0.80)",
                        },
                        "&:hover .MuiChip-deleteIcon": {
                          color: "rgba(61, 108, 174, 1)",
                        },
                      }}
                      {...restProps}
                    />
                  );
                })
              }
            />
          </Grid>
          
        </Grid>

        {[11, 3, 4, 5, 6].map((panelId) => (
          <iframe
            key={iframeSrc + `&panelId=${panelId}`}
            src={iframeSrc + `&panelId=${panelId}`}
            width="100%"
            height="300"
            frameBorder="0"
            title={`Grafico ${panelId}`}
          ></iframe>
        ))}
      </Box>
    </PageBody>
  );
}
